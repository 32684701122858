/**
 * ERROR TRACKING (sentry.io)
 * https://docs.sentry.io/error-reporting/quickstart
 */
import * as Sentry from '@sentry/vue';

export function initSentry(app, dsn, environment, options) {
  if (dsn) {
    Sentry.init({
      app,
      environment,
      dsn,
      integrations: [],
      beforeSend: options?.beforeSend,
      beforeBreadcrumb: options?.beforeBreadcrumb,
    });
  }
}
