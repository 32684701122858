export const SALES_PLATFORMS = {
  AMAZON: 'amazon',
  AMAZONVENDOR: 'amzvendor',
  AUTHORIZENET: 'authorizenet',
  BIGCOMMERCE: 'bigcommerce',
  BRAINTREE: 'braintree',
  PAYPAL: 'paypal',
  SHOPIFY: 'shopify',
  SHOPIFY_FIVETRAN: 'shopify_fivetran',
  SQUARE: 'square',
  STRIPE: 'stripe',
  WOOCOMMERCE: 'woocommerce',
};

export const MARKETING_PLATFORMS = {
  GOOGLEADS: 'googleads',
  FACEBOOK: 'facebook',
  FACEBOOKADS: 'facebookads',
  INSTAGRAM: 'instagram',
  AMAZONADS: 'amazonads',
};
