<template>
  <div v-if="isImprovedKyFlowEnabled">
    <FormError v-if="showError" :error-message="errorMessage" />
    <FormBusinessAddress
      @validationSuccess="handleValidationSuccess"
      @validationFailure="handleError"
      @goBack="goBack"
    />
  </div>
  <LayoutFormPage
    v-else
    :error-message="errorMessage"
    :show-error-message="showError"
  >
    <template #default>
      <FormRegisteredAddress
        ref="form"
        :event-tracking-fields="commonTrackingFields"
        @validationSuccess="handleValidationSuccess"
        @validationFailure="handleError"
        @goBack="goBack"
      />
    </template>
  </LayoutFormPage>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapStateModuleGetters } from '@/utils/state-modules';
import stateModuleAware from '@/mixins/state-module-aware';
import { FormRegisteredAddress, LayoutFormPage } from '@/components';
import FormBusinessAddress from '@/components/forms/FormBusinessAddress';
import FormError from '@/components/forms/shared/FormError';

export default {
  components: {
    FormRegisteredAddress,
    LayoutFormPage,
    FormBusinessAddress,
    FormError,
  },
  mixins: [stateModuleAware],
  data() {
    return {
      errorMessage: null,
      showError: false,
    };
  },
  computed: {
    ...mapGetters([
      'productSegmentLabel',
      'business',
      'user',
      'isImprovedKyFlowEnabled',
    ]),
    ...mapStateModuleGetters(['steps', 'previousStepName', 'experimentName']),
    currentRoute() {
      return this.$route.name;
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.currentRoute,
        experiment: this.experimentName,
      };
    },
  },
  methods: {
    async handleValidationSuccess() {
      this.dispatchToStateModule('UPDATE_STEP', {
        step: this.currentRoute,
      });
      this.$router.push({
        name: this.steps[this.currentRoute].nextRoute.name,
        params: { id: this.user.id },
      });
    },
    goBack() {
      // using local storage here because if the user goes to the previous step and clicks the Next button
      // the backend will update the business and the state will be overwritten in the store
      localStorage.setItem('business', JSON.stringify(this.business));
      this.$router.push({ name: this.previousStepName(this.currentRoute) });
    },
    handleError(error) {
      this.errorMessage = error;
      this.showError = true;
    },
  },
  mounted() {
    this.$store.dispatch('MODIFY_PAYMENT_STEPS');
  },
};
</script>
