<template>
  <div>
    <DataTable
      v-model:filters="tableFilters"
      v-model:first="currentPage"
      v-model:sort-field="tableSortedBy"
      filter-display="menu"
      :value="normalizedBills"
      class="table-component__spread-paginator-label"
      responsive-layout="scroll"
      selection-mode="single"
      data-cy="bills-table"
      :paginator="true"
      :rows="20"
      :paginator-template="{
        '640px':
          'CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink RowsPerPageDropdown',
        default:
          'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
      }"
      :rows-per-page-options="[10, 20, 50]"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      @rowSelect="onRowSelect"
      @sort="onTableSort"
    >
      <template #empty>
        {{ $t('account.pages.subPagePaymentTransactions.emptyVendorTable') }}
      </template>
      <Column
        field="formattedValuesForSorting.createdAt"
        sortable
        :header="$t('account.pages.subPagePaymentTransactions.dateCreated')"
        data-type="date"
      >
        <template #body="slotProps">
          {{ dueDateToEasternDateTime(slotProps?.data.createdAt) }}
        </template>
        <template #filter="{ filterModel }">
          <Calendar
            v-model="filterModel.value"
            name="calendar"
            :manual-input="false"
            placeholder="yyyy-mm-dd"
            show-icon
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <DSButton
            label="Apply"
            class="p-button-sm"
            @click="onFilterChange(filterCallback, 'Created Date')"
          />
        </template>
      </Column>
      <Column
        field="formattedValuesForSorting.id"
        sortable
        :header="
          $t('account.pages.subPagePaymentTransactions.invoiceReceiptId')
        "
      >
        <template #body="slotProps">
          {{ getShortBillId(slotProps?.data.id).toUpperCase() }}
        </template>
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            placeholder="Search by ID"
            @input="filterModel.value = filterModel.value.toUpperCase()"
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <DSButton
            label="Apply"
            class="p-button-sm"
            @click="onFilterChange(filterCallback, 'Id')"
          />
        </template>
      </Column>
      <Column
        field="formattedValuesForSorting.vendorName"
        sortable
        :header="$t('account.pages.subPagePaymentTransactions.vendor')"
      >
        <template #body="slotProps">
          {{ getVendorName(slotProps?.data) }}
        </template>
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            placeholder="Search by vendor"
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <DSButton
            label="Apply"
            class="p-button-sm"
            @click="onFilterChange(filterCallback, 'Vendor')"
          />
        </template>
      </Column>
      <Column
        field="formattedValuesForSorting.amount"
        sortable
        :header="$t('account.pages.subPagePaymentTransactions.amount')"
        data-type="numeric"
      >
        <template #body="slotProps">
          {{
            formatMoney(
              slotProps?.data.amountCents,
              slotProps?.data.payOutCurrency,
            )
          }}
          {{ slotProps?.data.payOutCurrency }}
        </template>
        <template #filter="{ filterModel }">
          <InputNumber
            v-model="filterModel.value"
            mode="decimal"
            locale="en-US"
            :min-fraction-digits="2"
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <DSButton
            label="Apply"
            class="p-button-sm"
            @click="onFilterChange(filterCallback, 'Amount')"
          />
        </template>
      </Column>
      <Column
        field="formattedValuesForSorting.dueDate"
        sortable
        :header="$t('account.pages.subPagePaymentTransactions.dueDate')"
        data-type="date"
      >
        <template #body="slotProps">
          {{
            slotProps?.data.isReceipt
              ? '-'
              : dueDateToEasternDateTime(slotProps?.data.dueDate)
          }}
        </template>
        <template #filter="{ filterModel }">
          <Calendar
            v-model="filterModel.value"
            name="calendar"
            :manual-input="false"
            placeholder="yyyy-mm-dd"
            show-icon
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <DSButton
            label="Apply"
            class="p-button-sm"
            @click="onFilterChange(filterCallback, 'Due Date')"
          />
        </template>
      </Column>
      <Column
        field="formattedValuesForSorting.uploadTypeLabel"
        :header="$t('account.pages.subPagePaymentTransactions.uploadType')"
        sortable
        :show-filter-match-modes="false"
        :filter-menu-class="$style['filter']"
      >
        <template #body="slotProps">
          {{
            slotProps?.data.isReceipt
              ? $t('account.pages.pageVendors.invoiceOrReceipt.receipt')
              : $t('account.pages.pageVendors.invoiceOrReceipt.invoice')
          }}
        </template>
        <template #filter="{ filterModel }">
          <ul>
            <li
              v-for="(label, index) in uniqueUploadTypeLabels"
              :key="`filter-status-${encodeURI(status)}-${index}`"
            >
              <Checkbox
                v-model="filterModel.value"
                :input-id="`filter-upload-type-${encodeURI(label)}-${index}`"
                :value="label"
              />
              <label :for="`filter-upload-type-${encodeURI(label)}-${index}`">{{
                label
              }}</label>
            </li>
          </ul>
        </template>
        <template #filterapply="{ filterCallback }">
          <DSButton
            label="Apply"
            class="p-button-sm"
            @click="onFilterChange(filterCallback, 'uploadType')"
          />
        </template>
      </Column>
      <Column
        field="formattedValuesForSorting.billStatusLabel"
        :header="$t('account.pages.subPagePaymentTransactions.status')"
        sortable
        :show-filter-match-modes="false"
        :filter-menu-class="$style['filter']"
      >
        <template #body="slotProps">
          <BillStatusBadge :bill="slotProps?.data" />
        </template>
        <template #filter="{ filterModel }">
          <ul>
            <li
              v-for="(status, index) in uniqueBillStatusLabels"
              :key="`filter-status-${encodeURI(status)}-${index}`"
            >
              <Checkbox
                v-model="filterModel.value"
                :input-id="`filter-status-${encodeURI(status)}-${index}`"
                :value="status"
              />
              <label :for="`filter-status-${encodeURI(status)}-${index}`">{{
                status
              }}</label>
            </li>
          </ul>
        </template>
        <template #filterapply="{ filterCallback }">
          <DSButton
            label="Apply"
            class="p-button-sm"
            @click="onFilterChange(filterCallback, 'Status')"
          />
        </template>
      </Column>
    </DataTable>
    <ModalBillDetailsActions
      ref="rowItemDetailsModal"
      @newPayment="this.$emit('newPayment')"
    />
  </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { mapGetters } from 'vuex';
import { useContextRoot } from '@/utils/context-root';
import { getBrowserWidth } from '@/utils/browser';
import billComposables from '@/composables/bills';
import BillStatusBadge from '@/components/payments/BillStatusBadge';
import InputText from '@clearbanc/clear-components/inputtext';
import Calendar from '@clearbanc/clear-components/calendar';
import DataTable from '@clearbanc/clear-components/datatable';
import InputNumber from '@clearbanc/clear-components/inputnumber';
import Column from '@clearbanc/clear-components/column';
import ModalBillDetailsActions from '@/components/payments/ModalBillDetailsActions';
import analytics from '@/utils/analytics';
import Checkbox from '@clearbanc/clear-components/checkbox';
import DSButton from '@clearbanc/clear-components/button';

import {
  getBillPaymentMethod,
  getShortBillId,
  getBillFees,
  dueDateToEasternDateTime,
} from '@/utils/payments';
import {
  BillPaymentMethods,
  BillStatus,
  CLEARPAY_SEGMENT_EVENTS,
} from '@/data/payments';
import { formatMoney } from '@/utils/currency';

export default {
  components: {
    InputText,
    InputNumber,
    Calendar,
    BillStatusBadge,
    DataTable,
    Column,
    ModalBillDetailsActions,
    Checkbox,
    DSButton,
  },
  emits: ['newPayment'],
  setup(props, context) {
    const ctxRoot = useContextRoot();
    const { billStatusData } = billComposables(context, ctxRoot);
    return { billStatusData };
  },
  data() {
    const initialTableFilters = {
      'formattedValuesForSorting.billStatusLabel': {
        value: null,
        matchMode: FilterMatchMode.IN,
      },
      'formattedValuesForSorting.vendorName': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      'formattedValuesForSorting.dueDate': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      'formattedValuesForSorting.createdAt': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      'formattedValuesForSorting.uploadTypeLabel': {
        value: null,
        matchMode: FilterMatchMode.IN,
      },
      'formattedValuesForSorting.id': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      'formattedValuesForSorting.amount': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    };

    return {
      tableFilters: initialTableFilters,
      initialTableFilters,
      currentPage: 0,
      tableSortedBy: undefined,
      uniqueBillStatusLabels: [],
      uniqueUploadTypeLabels: [],
    };
  },
  computed: {
    ...mapGetters([
      'vendors',
      'bills',
      'business',
      'businessId',
      'selectedBill',
      'contracts',
    ]),
    normalizedBills() {
      return this.bills.map((bill) => {
        // Get a list of all the unique status labels
        const formattedBillLabel = this.getFormattedBillStatusLabel(bill.id);

        if (!this.uniqueBillStatusLabels.includes(formattedBillLabel)) {
          this.uniqueBillStatusLabels.push(formattedBillLabel);
        }

        // Get a list of all the unique upload types
        const formattedUploadTypeLabel = bill.isReceipt
          ? this.$t('account.pages.pageVendors.invoiceOrReceipt.receipt')
          : this.$t('account.pages.pageVendors.invoiceOrReceipt.invoice');

        if (!this.uniqueUploadTypeLabels.includes(formattedUploadTypeLabel)) {
          this.uniqueUploadTypeLabels.push(formattedUploadTypeLabel);
        }

        return {
          ...bill,
          formattedValuesForSorting: {
            billStatusLabel: formattedBillLabel,
            vendorName:
              (bill.isReceipt
                ? bill.receiptVendorName
                : bill.beneficiaryName) ?? '-',
            dueDate: bill.isReceipt
              ? null
              : this.convertDateToFilters(bill.dueDate),
            createdAt: this.convertDateToFilters(bill.createdAt),
            uploadTypeLabel: formattedUploadTypeLabel,
            id: getShortBillId(bill.id).toUpperCase(),
            amount: bill.amountCents / 100,
          },
          vendor: this.vendors.find((v) => {
            return v.id === bill.vendorId;
          }),
        };
      });
    },
  },
  async mounted() {
    // trigger the billdetails modal if we have bill id as a query param
    if (this.$route?.query?.billId) {
      await this.$store.dispatchApiAction('GET_BILL', {
        id: this.$route?.query?.billId,
      });
      this.$refs.rowItemDetailsModal.open(this.selectedBill);
    }
  },
  methods: {
    getBillPaymentMethod,
    getShortBillId,
    getBillFees,
    dueDateToEasternDateTime,
    formatMoney,
    onTableSort(e) {
      const dataTypes = {
        'formattedValuesForSorting.billStatusLabel': 'Status',
        'formattedValuesForSorting.vendorName': 'Vendor',
        'formattedValuesForSorting.dueDate': 'Due Date',
        'formattedValuesForSorting.createdAt': 'Created Date',
        'formattedValuesForSorting.uploadTypeLabel': 'Upload Type',
        'formattedValuesForSorting.id': 'Id',
        'formattedValuesForSorting.amount': 'Amount',
      };

      analytics.track('fe_sorting_button_clicked', {
        dataType: dataTypes[e.sortField],
        sortingType: e.sortOrder === 1 ? 'A-Z' : 'Z-A',
      });
    },
    onFilterChange(applyFilters, type) {
      applyFilters();
      analytics.track('fe_filtering_button_clicked', {
        dataType: type,
      });
    },
    resetFilters() {
      this.currentPage = 0;
      this.tableSortedBy = undefined;
      this.tableFilters = this.initialTableFilters;
      analytics.track('fe_clear_filter_button_clicked');
    },
    getFormattedBillStatusLabel(billId) {
      const billContract = this.contracts.find(
        (contract) => contract.billId === billId,
      );

      const statusData = this.billStatusData(
        billId,
        billContract,
        undefined,
        true,
      );
      const statusLabel =
        statusData && this.$t(statusData?.milestoneInProgress.label);

      return statusLabel;
    },
    async onRowSelect(selectedRowItem) {
      let currentBill = selectedRowItem.data;

      if (currentBill.billId) {
        // GET bill details if selectedRowItem is a transaction
        await this.$store.dispatchApiAction('GET_BILL', {
          id: currentBill.billId,
        });
        currentBill = this.selectedBill;
      }
      this.$refs.rowItemDetailsModal.open(currentBill);
      if (currentBill.paymentMethod !== BillPaymentMethods.BNPL) {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_BILL_DETAILS, {
          businessId: this.businessId,
          businessName: this.business.name,
          billId: currentBill.id,
          shortBillId: getShortBillId(currentBill.id),
        });
      }
      if (currentBill.paymentMethod === BillPaymentMethods.BNPL) {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_BNPL_BILL_DETAILS, {
          businessId: this.businessId,
          businessName: this.business.name,
          billId: currentBill.id,
          uploadType: currentBill.isReceipt ? 'receipt' : 'invoice',
          shortBillId: getShortBillId(currentBill.id),
        });
      }

      analytics.track('if_user_view_submitted_bill', {
        type: this.selectedBill.isReceipt ? 'Receipt' : 'Invoice',
        browserWidth: getBrowserWidth(),
      });
    },
    convertDateToFilters(date) {
      return new Date(date.replace(/-/g, '/').replace(/T.+/, ''));
    },
    getVendorName(bill) {
      if (!bill) return '-';

      if (bill.isReceipt) {
        // It seems that along the beneficiary name, when the status is cancelled, it also gets appended the
        // cancellation reason. E.G.: "Vendor Name; Cancellation Reason"
        // We only want to display the vendor name in this case
        if (bill.status === BillStatus.CANCELLED && bill.memo)
          return bill.memo.split(';')[0];
        return bill.receiptVendorName ?? '-';
      }

      return bill.beneficiaryName || '-';
    },
  },
};
</script>

<style lang="less" module>
.filter {
  [class^='p-column-filter-constraint'] {
    padding-bottom: 0;
  }

  li + li {
    margin-top: 10px;
  }

  div + label {
    margin-left: 10px;
  }

  label {
    cursor: pointer;
  }
}
</style>
