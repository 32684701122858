<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { FormNewOwner } from '@/components';
import FormError from '@/components/forms/shared/FormError';
import { useRouter, useRoute } from 'vue-router';

const route = useRoute();
const router = useRouter();
const store = useStore();

const errorMessage = ref(null);

const selectedOwner = computed(() => {
  return store.getters.allOwners.find((owner) => owner.id === route.params.id);
});

onMounted(() => {
  if (route.params.id) {
    if (
      !selectedOwner.value ||
      store.getters.ownerHasPendingBillContractOrActiveAdvance
    ) {
      router.replace({ name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST });
      return;
    }
    if (selectedOwner.value?.isPrimary) {
      store.dispatch('SET_SELECTED_OWNER', selectedOwner.value.id);
      router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE,
        params: { id: selectedOwner.value.id },
      });
    }
  }
});

function handleError(error) {
  errorMessage.value = error;
}

function handleSuccess() {
  router.push({ name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST });
}

function handleBack() {
  router.push({ name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST });
}
</script>

<template>
  <div>
    <FormError v-if="!!errorMessage" :error-message="errorMessage" />
    <div :class="$style['owner-wrapper']">
      <FormNewOwner
        :owner="selectedOwner"
        @onBack="handleBack"
        @onSuccess="handleSuccess"
        @onError="handleError"
      />
    </div>
  </div>
</template>

<style lang="less" module scoped>
.owner-wrapper {
  max-width: 556px;
  margin: 0 auto;
}
</style>
