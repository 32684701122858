// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_VnBYN[data-v-1d99d8dc]{margin:40px 0 0}div[class^=c-form__row][data-v-1d99d8dc]:empty{margin:0}.field-heading_ZfKi1[data-v-1d99d8dc]{color:var(--neutral-grey-400,#5f5f61);font-size:16px;font-weight:500;line-height:24px;margin:0 0 4px 0}.field-description_RQG4T[data-v-1d99d8dc],.field-heading_ZfKi1[data-v-1d99d8dc]{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-style:normal}.field-description_RQG4T[data-v-1d99d8dc]{color:var(--neutral-grey-500,#303032);font-size:14px;font-weight:300;line-height:20px;margin:0 0 20px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form_VnBYN",
	"field-heading": "field-heading_ZfKi1",
	"field-description": "field-description_RQG4T"
};
export default ___CSS_LOADER_EXPORT___;
