// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".prevent-click_L8Uuw *{pointer-events:none}.redacted_S29pY{position:relative}.redacted__overlay_N68L4{position:absolute;top:24px;left:0;width:100%;height:50px;border:1px solid #ceced0;background:repeating-linear-gradient(45deg,#f3f3f3,#f3f3f3 12px,#fff 0,#fff 20px);border-radius:4px;z-index:1;cursor:pointer}.redacted--full-height_Np5Xs .redacted__overlay_N68L4{top:0;height:100%}i.redacted__icon_UAfx1{position:absolute;top:24px;right:0;z-index:2;padding:15px;pointer-events:none;font-size:20px;display:block;color:green}.redacted--full-height_Np5Xs i.redacted__icon_UAfx1{top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prevent-click": "prevent-click_L8Uuw",
	"redacted": "redacted_S29pY",
	"redacted__overlay": "redacted__overlay_N68L4",
	"redacted--full-height": "redacted--full-height_Np5Xs",
	"redacted__icon": "redacted__icon_UAfx1"
};
export default ___CSS_LOADER_EXPORT___;
