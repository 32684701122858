import _ from 'lodash';

import {
  isSalesAccountConnected,
  isMarketingAccountConnected,
  isAccountingAccountConnected,
} from '@/utils/external-accounts';
import {
  isBasicUserDetailsStepComplete,
  isBasicBusinessDetailsStepComplete,
  isOffersStepComplete,
  isConnectSalesAccountsStepComplete,
  isPlaidConnected,
  isConnectBankingStepComplete,
  isBankLinkConfirmationStepComplete,
  isUploadBankStatementsStepComplete,
  isBusinessDetailsStepComplete,
  isBusinessDetailsComplete,
  isRegisteredAddressStepComplete,
  isBusinessFormationDocsStepComplete,
  isOwnersStepComplete,
  isOwnersListStepComplete,
  isOwnersStepOneComplete,
  findIncompleteOwner,
  isOwnersComplete,
  isAdvanceContractStepComplete,
  isBankContractStepComplete,
  isMissedStepsStepComplete,
  wasMarketingStepSkipped,
  wasAccountingStepSkipped,
  wasPlaidConnectStepSkipped,
  wasUploadBankStatementsStepSkipped,
} from '@/utils/onboarding-steps';
import {
  ONBOARDING_CONFIG,
  ONBOARDING_STAGE,
  ONBOARDING_2_ROUTES,
  ONBOARDING_2_POST_OFFER_STEP_GROUPS,
  ONBOARDING_STEP_GROUPS,
} from '@/data/onboarding';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import i18n from '@/plugins/i18n';

export default {
  state: {
    defaultConfigs: ONBOARDING_CONFIG,
    experimentName: '',
    featureFlags: {},
    offersPage: '',
    steps: {},
    applicationProgress: [
      {
        stepLabel: () =>
          i18n.t('modules.onboardingModule.applicationProgress.requestFunding'),
        percentComplete: 0,
      },
      {
        stepLabel: () =>
          i18n.t('modules.onboardingModule.applicationProgress.selectOffer'),
        percentComplete: 0,
      },
      {
        stepLabel: () =>
          i18n.t(
            'modules.onboardingModule.applicationProgress.completeApplication',
          ),
        percentComplete: 0,
      },
      {
        stepLabel: () =>
          i18n.t('modules.onboardingModule.applicationProgress.receiveFunds'),
        percentComplete: 0,
      },
    ],
    userIsInDiligence: false,
    stepGroups: {},
    stepsUpdated: false,
    isRevisitingMissedStep: false,
  },
  getters: {
    onboardingStateModule: (_state, _getters, rootState) =>
      rootState.currentStateModule,
    onboardingSteps: (state) => state.steps,
    onboardingExperimentName: (state) => state.experimentName,
    onboardingFeatureFlags: (state) => state.featureFlags,
    onboardingOffersPage: (state) => state.offersPage,
    applicationProgress: (state) => state.applicationProgress,
    userIsInDiligence: (state) => state.userIsInDiligence,
    stepGroups: (state) => state.stepGroups,
    stepsUpdated: (state) => state.stepsUpdated,
    isRevisitingMissedStep: (state) => state.isRevisitingMissedStep,
    isSelectOfferStepComplete: (state) => {
      return state.applicationProgress[1].percentComplete === 100;
    },
    allBusinessProfiles: (state, getters) => {
      const userInvites = getters.userInvites ?? [];
      const noPendingUsers = (getters.businessOwners ?? []).filter((owner) => {
        const userInvite = userInvites.find(
          (invite) => invite.invitedUserId === Number(owner.id),
        );
        return !userInvite || userInvite.fulfilledAt;
      });
      return [{ ...getters.user }, ...noPendingUsers];
    },
    firstIncompleteStep: (state, getters) => {
      return {
        preOffer: Object.values(getters.onboardingSteps).find(
          (val) => !val.complete && val.stage === ONBOARDING_STAGE.PRE_OFFER,
        ),
        postOffer: Object.values(getters.onboardingSteps).find(
          (val) => !val.complete && val.stage === ONBOARDING_STAGE.POST_OFFER,
        ),
        all: Object.values(getters.onboardingSteps).find(
          (val) => !val.complete,
        ),
      };
    },
    isRequiredBankingStepsIncomplete: (state, getters) => {
      const requiredBankingSteps = [
        ONBOARDING_2_ROUTES.CONNECT_BANKING,
        ONBOARDING_2_ROUTES.BANK_LINK_CONFIRMATION,
      ];
      return requiredBankingSteps.every(
        (step) => !getters.onboardingSteps[step].complete,
      );
    },
    navigationPreviewStep: (state, getters) => {
      let firstIncompleteBusinessDetailsStep = Object.values(
        getters.onboardingSteps,
      ).find(
        (val) =>
          !val.complete &&
          val.stage === ONBOARDING_STAGE.POST_OFFER &&
          val.group === ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS &&
          val.skippable === false,
      );

      /**
       * automatically navigate to the root owners page if on owners step as specific step pages require
       * params. additional routing is handled in owners/index.vue
       */
      const { OWNERS_STEP_ONE, OWNERS_STEP_TWO, OWNERS } = ONBOARDING_2_ROUTES;
      if (
        [OWNERS_STEP_ONE, OWNERS_STEP_TWO].includes(
          firstIncompleteBusinessDetailsStep?.name,
        )
      ) {
        firstIncompleteBusinessDetailsStep = getters.onboardingSteps[OWNERS];
      }

      const firstIncompleteStep = getters.firstIncompleteStep.postOffer;

      if (
        !isOffersStepComplete(getters.advanceInNegotiation, getters.business)
      ) {
        return firstIncompleteStep;
      } else if (
        !isMarketingAccountConnected(getters.externalAccounts) &&
        !wasMarketingStepSkipped(getters)
      ) {
        return getters.onboardingSteps[ONBOARDING_2_ROUTES.CONNECT_MARKETING];
      } else if (
        !isAccountingAccountConnected(getters.externalAccounts) &&
        !wasAccountingStepSkipped(getters)
      ) {
        return getters.onboardingSteps[ONBOARDING_2_ROUTES.CONNECT_ACCOUNTING];
      } else if (
        !isPlaidConnected(getters) &&
        !wasPlaidConnectStepSkipped(getters)
      ) {
        return getters.onboardingSteps[ONBOARDING_2_ROUTES.CONNECT_BANKING];
      } else if (
        isPlaidConnected(getters) &&
        !isBankLinkConfirmationStepComplete(getters)
      ) {
        return getters.onboardingSteps[
          ONBOARDING_2_ROUTES.BANK_LINK_CONFIRMATION
        ];
      } else if (
        !isUploadBankStatementsStepComplete(getters) &&
        !wasUploadBankStatementsStepSkipped(getters)
      ) {
        return getters.onboardingSteps[
          ONBOARDING_2_ROUTES.BANK_FAILED_UPLOAD_DOCS
        ];
      } else if (
        !isBusinessDetailsComplete(getters.business) ||
        !isRegisteredAddressStepComplete(getters.business) ||
        !isBusinessFormationDocsStepComplete(getters) ||
        getters.business.corpCountry === COUNTRY_CODES.US ||
        !isOwnersStepOneComplete(
          getters.allBusinessProfiles,
          getters.uploads,
        ) ||
        findIncompleteOwner(getters.allBusinessProfiles) ||
        !isOwnersListStepComplete(getters.user, getters.authorizedSignatory)
      ) {
        return firstIncompleteBusinessDetailsStep;
      } else if (
        !isMissedStepsStepComplete(getters.missingSkippablePostOfferSteps)
      ) {
        return getters.onboardingSteps[ONBOARDING_2_ROUTES.MISSED_STEPS];
      } else if (!isAdvanceContractStepComplete(getters)) {
        return getters.onboardingSteps[
          ONBOARDING_2_ROUTES.SIGN_ADVANCE_CONTRACT
        ];
      } else if (!isBankContractStepComplete(getters)) {
        return getters.onboardingSteps[ONBOARDING_2_ROUTES.SIGN_ACH_CONTRACT];
      }

      return (
        getters.nextRequiredAndIncompletePostOfferStep ??
        getters.onboardingSteps[ONBOARDING_2_ROUTES.APPLICATION_CONFIRMATION]
      );
    },
    applicationConfirmationStep: (state, getters) =>
      getters.onboardingSteps[ONBOARDING_2_ROUTES.APPLICATION_CONFIRMATION],
    missingSkippablePostOfferSteps: (state, getters) => {
      const stepsToExclude = [
        ONBOARDING_2_ROUTES.CONNECT_ACCOUNTING,
        ONBOARDING_2_ROUTES.BUSINESS_UPLOAD_EIN,
      ];
      if (getters.business.corpCountry === COUNTRY_CODES.DE)
        stepsToExclude.push(ONBOARDING_2_ROUTES.CONNECT_BANKING);
      const allMissedSteps = Object.values(getters.onboardingSteps).filter(
        (step) =>
          !step.complete &&
          step.stage === ONBOARDING_STAGE.POST_OFFER &&
          step.skippable === true &&
          !stepsToExclude.includes(step.name),
      );
      const allMissedStepNames = allMissedSteps.map((step) => {
        return step.name;
      });
      // if connect-banking and upload-docs steps are incomplete, user entirely skipped all banking steps
      if (
        allMissedStepNames.includes(ONBOARDING_2_ROUTES.CONNECT_BANKING) &&
        allMissedStepNames.includes(ONBOARDING_2_ROUTES.BANK_FAILED_UPLOAD_DOCS)
      ) {
        // so the Missed A Step page should only show the connect-banking (plaid) tile
        return allMissedSteps.filter(
          (step) =>
            ![
              ONBOARDING_2_ROUTES.BANK_LINK_CONFIRMATION,
              ONBOARDING_2_ROUTES.BANK_FAILED_UPLOAD_DOCS,
            ].includes(step.name),
        );
      }
      return allMissedSteps;
    },
    postOfferStepNames: (state, getters) => {
      return Object.values(getters.onboardingSteps).reduce((acc, step) => {
        if (step.stage === ONBOARDING_STAGE.POST_OFFER) acc.push(step.name);
        return acc;
      }, []);
    },
    completedPostOfferSteps: (state, getters) => {
      return Object.keys(getters.onboardingSteps).filter(
        (step) => step.complete && step.stage === ONBOARDING_STAGE.POST_OFFER,
      );
    },
    previousStepName: (state, getters) => (currentStepName) => {
      const stepNames = Object.keys(getters.onboardingSteps);
      return stepNames[stepNames.indexOf(currentStepName) - 1];
    },
    skippablePostOfferSteps: (state, getters) => {
      return Object.values(getters.onboardingSteps).filter(
        (step) => step.stage === ONBOARDING_STAGE.POST_OFFER && step.skippable,
      );
    },
    // steps that only show for specific users (ie. US incorporated businesses)
    conditionalPostOfferSteps: (state, getters) => {
      return Object.values(getters.onboardingSteps).filter(
        (step) =>
          step.stage === ONBOARDING_STAGE.POST_OFFER && step.conditional,
      );
    },
    bankingPostOfferSteps: (state, getters) => {
      return Object.values(getters.onboardingSteps).filter(
        (step) =>
          step.stage === ONBOARDING_STAGE.POST_OFFER && step.type === 'banking',
      );
    },
    nextRequiredAndIncompletePostOfferStep: (state, getters) => {
      return Object.values(getters.onboardingSteps).find(
        (step) =>
          !step.complete &&
          step.stage === ONBOARDING_STAGE.POST_OFFER &&
          step.skippable === false,
      );
    },
    nextStepForMcaContractPage: (_state, getters) => {
      const { APPLICATION_CONFIRMATION, SIGN_ACH_CONTRACT } =
        ONBOARDING_2_ROUTES;

      const nextStepRoute = isBankContractStepComplete(getters)
        ? APPLICATION_CONFIRMATION
        : SIGN_ACH_CONTRACT;

      return getters.onboardingSteps[nextStepRoute];
    },
    isOwnersFormComplete: (state, getters) => {
      return isOwnersComplete(getters);
    },
    isPostOfferApplicationComplete: (_state, getters) => {
      return !getters.nextRequiredAndIncompletePostOfferStep;
    },
  },
  actions: {
    UPDATE_ONBOARDING_STATE_MODULE: (
      { state, _commit, dispatch },
      stateModule,
    ) => {
      const config = state.defaultConfigs[stateModule];
      dispatch('SET_ONBOARDING_STEPS', config.steps);
      dispatch('SET_EXPERIMENT_NAME', config.experimentName);
      if (config.featureFlag) {
        dispatch('UPDATE_FEATURE_FLAG', {
          name: config.featureFlag,
          value: true,
        });
      }
      dispatch('SET_ONBOARDING_OFFERS_PAGE', config.offersPage);
    },
    SET_ONBOARDING_STEPS: ({ commit }, steps) => {
      commit('SET_ONBOARDING_STEPS', steps);
    },
    UPDATE_ALL_ONBOARDING_STEPS: ({ state, dispatch }) => {
      Object.keys(state.steps).forEach((step) =>
        dispatch('UPDATE_ONBOARDING_STEP', { step }),
      );
    },
    UPDATE_ONBOARDING_STEP: (
      { state, commit, rootGetters, getters },
      { step, wasSkipped },
    ) => {
      function findCompleted(currentStep) {
        switch (currentStep) {
          case 'onboarding-unified':
            return true;
          case ONBOARDING_2_ROUTES.SIGN_UP:
            return true;
          case ONBOARDING_2_ROUTES.BASIC_USER_DETAILS:
            return isBasicUserDetailsStepComplete(rootGetters.user);
          case ONBOARDING_2_ROUTES.BASIC_BUSINESS_DETAILS:
            return isBasicBusinessDetailsStepComplete(rootGetters.business);
          case ONBOARDING_2_ROUTES.CONNECT_SALES:
            return isConnectSalesAccountsStepComplete(
              rootGetters.externalAccounts,
            );
          case ONBOARDING_2_ROUTES.SELECT_PRODUCT:
            return rootGetters.advances.length;
          case ONBOARDING_2_ROUTES.WAITING_ROOM:
            return true;
          case ONBOARDING_2_ROUTES.OFFER_SELECTION:
            return isOffersStepComplete(
              rootGetters.advanceInNegotiation,
              rootGetters.business,
            );
          case ONBOARDING_2_ROUTES.CONNECT_MARKETING:
            return isMarketingAccountConnected(rootGetters.externalAccounts);
          case ONBOARDING_2_ROUTES.CONNECT_ACCOUNTING:
            return isAccountingAccountConnected(rootGetters.externalAccounts);
          case ONBOARDING_2_ROUTES.CONNECT_BANKING:
            return isConnectBankingStepComplete(rootGetters);
          case ONBOARDING_2_ROUTES.BANK_LINK_CONFIRMATION:
            return (
              isBankLinkConfirmationStepComplete(rootGetters) ||
              rootGetters.hasUploadedBankDocs
            );
          case ONBOARDING_2_ROUTES.BANK_FAILED_UPLOAD_DOCS:
            return isUploadBankStatementsStepComplete(rootGetters);
          case ONBOARDING_2_ROUTES.BUSINESS_DETAILS:
            return isBusinessDetailsComplete(rootGetters.business);
          case ONBOARDING_2_ROUTES.BUSINESS_REGISTERED_ADDRESS:
            return (
              isRegisteredAddressStepComplete(rootGetters.business) &&
              isBusinessDetailsComplete(rootGetters.business)
            );
          case ONBOARDING_2_ROUTES.BUSINESS_INCORP_DOCS:
            return isBusinessFormationDocsStepComplete(rootGetters);
          case ONBOARDING_2_ROUTES.OWNERS_STEP_ONE:
            return isOwnersStepOneComplete(
              getters.allBusinessProfiles,
              rootGetters.uploads,
            );
          case ONBOARDING_2_ROUTES.OWNERS_STEP_TWO:
            return !findIncompleteOwner(getters.allBusinessProfiles);
          case ONBOARDING_2_ROUTES.IDENTITY_VERIFICATION:
            return true;
          case ONBOARDING_2_ROUTES.OWNERS_LIST:
            return (
              isOwnersListStepComplete(
                rootGetters.user,
                rootGetters.authorizedSignatory,
              ) && isBusinessFormationDocsStepComplete(rootGetters)
            );
          case ONBOARDING_2_ROUTES.SIGN_ADVANCE_CONTRACT:
            return isAdvanceContractStepComplete(rootGetters);
          case ONBOARDING_2_ROUTES.SIGN_ACH_CONTRACT:
            return isBankContractStepComplete(rootGetters);
          case ONBOARDING_2_ROUTES.APPLICATION_CONFIRMATION:
            return true;
          case ONBOARDING_2_ROUTES.MISSED_STEPS:
            return isMissedStepsStepComplete(
              getters.missingSkippablePostOfferSteps,
            );
          default:
            return false;
        }
      }

      const updatedStep = {
        ...state.steps[step],
        complete: findCompleted(step),
      };

      if (wasSkipped === undefined) {
        const storedSkip = localStorage.getItem(`${step}-skipped`);
        if (storedSkip) {
          updatedStep.wasSkipped = JSON.parse(storedSkip);
        }
      } else {
        updatedStep.wasSkipped = wasSkipped;
        localStorage.setItem(`${step}-skipped`, `${wasSkipped}`);
      }

      if (findCompleted(ONBOARDING_2_ROUTES.MISSED_STEPS)) {
        commit('SET_REVISITING_MISSED_STEP', false);
      }

      commit('UPDATE_ONBOARDING_STEP', updatedStep);
    },
    UPDATE_SKIPPABLE_STATUS_FOR_CONDITIONAL_ONBOARDING_STEPS: ({
      state,
      commit,
      rootGetters,
      getters,
    }) => {
      const skippableState = {
        [ONBOARDING_2_ROUTES.BUSINESS_UPLOAD_EIN]: !rootGetters.isUSBusiness,
        [ONBOARDING_2_ROUTES.BANK_LINK_CONFIRMATION]:
          rootGetters.hasUploadedBankDocs,
      };
      for (const step of getters.conditionalPostOfferSteps) {
        const updatedStep = {
          ...state.steps[step.name],
          skippable: skippableState[step.name],
        };
        commit('UPDATE_ONBOARDING_STEP', updatedStep);
      }
    },
    UPDATE_ALL_ONBOARDING_BANKING_STEPS: ({ getters, dispatch }) => {
      for (const step of getters.bankingPostOfferSteps) {
        dispatch('UPDATE_ONBOARDING_STEP', { step: step.name });
      }
    },
    SET_EXPERIMENT_NAME: ({ commit }, experimentName) => {
      commit('SET_EXPERIMENT_NAME', experimentName);
    },
    UPDATE_FEATURE_FLAG: ({ commit }, flag) => {
      commit('UPDATE_FEATURE_FLAG', flag);
    },
    SET_ONBOARDING_OFFERS_PAGE: ({ commit }, offersPage) => {
      commit('SET_ONBOARDING_OFFERS_PAGE', offersPage);
    },
    UPDATE_PROGRESS: ({ commit, rootGetters }) => {
      const advanceInNegotiation = rootGetters.advanceInNegotiation;
      const advanceContracts = rootGetters.advanceContracts;
      const isAdvanceInNegotiationContractSigned =
        !!advanceContracts[advanceInNegotiation.id]?.userSignedAt;
      const isRequestOfferStepComplete = () => {
        if (isSalesAccountConnected(rootGetters.externalAccounts)) {
          // fill tracker to 20% of distance between Request Offer and Select Offer
          commit('UPDATE_PROGRESS_PERCENT', {
            stepIndex: 0,
            percentComplete: 100,
          });
          commit('UPDATE_PROGRESS_PERCENT', {
            stepIndex: 1,
            percentComplete: 20,
          });
          return true;
        }
        return false;
      };

      const isUserOnSelectOfferStep = () => {
        if (
          rootGetters.userHasAdvanceOffer ||
          isAdvanceInNegotiationContractSigned
        ) {
          // fill tracker to 99% before Select Offer
          commit('UPDATE_PROGRESS_PERCENT', {
            stepIndex: 1,
            percentComplete: 99,
          });
          return true;
        }
        return false;
      };

      const isSelectOfferStepComplete = () => {
        if (
          isOffersStepComplete(
            rootGetters.advanceInNegotiation,
            rootGetters.business,
          )
        ) {
          // complete select offer step
          commit('UPDATE_PROGRESS_PERCENT', {
            stepIndex: 1,
            percentComplete: 100,
          });
          // fill tracker to 50% after Select Offer
          commit('UPDATE_PROGRESS_PERCENT', {
            stepIndex: 2,
            percentComplete: 50,
          });
          return true;
        }
        return false;
      };

      const isUserOnCompleteApplicationStep = () => {
        const percentToAddPerStep = 5;
        let numberOfSelfServeStepsCompleted = 0;

        // connect sales account step
        if (rootGetters.hasActiveSalesAccounts) {
          numberOfSelfServeStepsCompleted++;
        }

        // select offers step (this will always be true if execution gets to this point)
        if (
          isOffersStepComplete(
            rootGetters.advanceInNegotiation,
            rootGetters.business,
          )
        ) {
          numberOfSelfServeStepsCompleted++;
        }

        // business details step
        if (isBusinessDetailsStepComplete(rootGetters.business)) {
          numberOfSelfServeStepsCompleted++;
        }

        // contract step
        if (rootGetters.isAdvanceContractSigned) {
          numberOfSelfServeStepsCompleted++;
        }

        // this is for the business verification step
        if (rootGetters.businessDetailsComplete) {
          numberOfSelfServeStepsCompleted++;
        }

        // owners step
        if (
          isOwnersStepComplete(
            rootGetters.businessOwners,
            rootGetters.isPrimaryUserInfoComplete,
            rootGetters.isOwnerInfoComplete,
          )
        ) {
          numberOfSelfServeStepsCompleted++;
        }

        // connect bank accounts step
        if (
          rootGetters.primaryBankAccountAuthorizedGoCardless ||
          rootGetters.isPrimaryBankAccountContractSigned ||
          rootGetters.uploadsMatching(rootGetters.bankUploadMatcher).length > 0
        ) {
          numberOfSelfServeStepsCompleted++;
        }

        // business uploads step
        if (rootGetters.uploadsComplete) {
          numberOfSelfServeStepsCompleted++;
        }

        // add 5% for each application step completed to the Submit Application step in tracker
        if (numberOfSelfServeStepsCompleted) {
          commit('UPDATE_PROGRESS_PERCENT', {
            stepIndex: 2,
            percentComplete:
              50 + numberOfSelfServeStepsCompleted * percentToAddPerStep,
          });
        }
        // TODO: Make this dynamic once we have all the steps in this module
        // there are 8 required self-serve steps to complete before user can submit their application
        return numberOfSelfServeStepsCompleted === 8;
      };

      const isUserInDiligence = () => {
        // Once users finish the self_serve application they'll automatically be in diligence.
        // This conditional will always be true since signing is a required step in the application
        if (isAdvanceInNegotiationContractSigned) {
          commit('UPDATE_PROGRESS_PERCENT', {
            stepIndex: 2,
            percentComplete: 100,
          });
          commit('UPDATE_PROGRESS_PERCENT', {
            stepIndex: 3,
            percentComplete: 60,
          });
          commit('UPDATE_USER_IS_IN_DILIGENCE', { value: true });
          return true;
        }
        return false;
      };

      // This array holds all the functions responsible for updating the progress tracker in the order they should be completed
      const updateProgressTrackerFunctions = [
        isRequestOfferStepComplete,
        isUserOnSelectOfferStep,
        isSelectOfferStepComplete,
        isUserOnCompleteApplicationStep,
        isUserInDiligence,
      ];

      // The next trackerFunction will only run if the the current trackerFunction returns true
      for (const trackerFunction of updateProgressTrackerFunctions) {
        if (!trackerFunction()) {
          break;
        }
      }
    },
    UPDATE_STEP_GROUPS: ({ getters, commit, dispatch }) => {
      // make sure the completion status of each step is updated
      dispatch('UPDATE_ALL_ONBOARDING_STEPS');
      const groups = _.cloneDeep(ONBOARDING_STEP_GROUPS);

      // find the first incomplete navigation step
      const incompleteStep = getters.navigationPreviewStep;
      if (!incompleteStep?.group) return;
      groups[incompleteStep.group].active = true;
      // This funciton assumes that the user is already past offer selection
      // update the conditionally rendered text in first step group
      const connectAccountsText = [];
      // this logic assumes that the accounting step being complete means marketing is also complete
      const accountingConnected =
        getters.onboardingSteps[ONBOARDING_2_ROUTES.CONNECT_ACCOUNTING]
          .complete;
      const marketingConnected =
        getters.onboardingSteps[ONBOARDING_2_ROUTES.CONNECT_MARKETING].complete;

      if (!accountingConnected || !marketingConnected) {
        connectAccountsText.push(
          `${i18n.t('data.onboarding.steps.navigationPreview.connect', {
            marketing: !marketingConnected
              ? i18n.t('data.onboarding.steps.navigationPreview.marketing')
              : '',
            and:
              !marketingConnected && !accountingConnected
                ? i18n.t('data.onboarding.steps.navigationPreview.and')
                : '',
            accounting: !accountingConnected
              ? i18n.t('data.onboarding.steps.navigationPreview.accounting')
              : '',
          })}`,
        );
      }
      connectAccountsText.push(
        i18n.t('data.onboarding.steps.navigationPreview.setUpBanking'),
      );

      groups[
        ONBOARDING_2_POST_OFFER_STEP_GROUPS.CONNECT_ACCOUNTS
      ].visibleStepsText = () => connectAccountsText;

      commit('SET_NAVIGATION_PREVIEW', groups);
    },
    SET_STEPS_SETUP_UPDATED: ({ commit }, value) => {
      commit('SET_STEPS_SETUP_UPDATED', value);
    },
  },
  mutations: {
    SET_ONBOARDING_STEPS: (state, steps) => {
      state.steps = steps;
    },
    UPDATE_ONBOARDING_STEP: (state, updatedStep) => {
      state.steps[updatedStep.name] = updatedStep;
    },
    SET_EXPERIMENT_NAME: (state, experimentName) => {
      state.experimentName = experimentName;
    },
    UPDATE_FEATURE_FLAG: (state, flag) => {
      state.featureFlags[flag.name] = flag.value;
    },
    SET_ONBOARDING_OFFERS_PAGE: (state, offersPage) => {
      state.offersPage = offersPage;
    },
    UPDATE_PROGRESS_PERCENT: (state, { stepIndex, percentComplete }) => {
      state.applicationProgress[stepIndex] = {
        stepLabel: state.applicationProgress[stepIndex].stepLabel,
        percentComplete,
      };
    },
    UPDATE_USER_IS_IN_DILIGENCE: (state, { value }) => {
      state.userIsInDiligence = value;
    },
    SET_NAVIGATION_PREVIEW: (state, stepGroups) => {
      state.stepGroups = stepGroups;
    },
    SET_STEPS_SETUP_UPDATED: (state, status) => {
      state.stepsUpdated = status;
    },
    SET_REVISITING_MISSED_STEP: (state, status) => {
      state.isRevisitingMissedStep = status;
    },
  },
};
