import { computed } from 'vue';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

export default (context) => {
  const ctx = context.root;
  const { getters } = ctx.$store;

  const user = computed(() => getters.user);

  const ownerVerificationStepComplete = computed(
    () => !!user.value?.agreedToKyc,
  );

  const redirectToCorrectOwnersPage = () => {
    ctx.$router
      .push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST,
      })
      .catch(() => {});
  };

  return {
    ownerVerificationStepComplete,
    redirectToCorrectOwnersPage,
  };
};
