<template>
  <div data-cy="bill-details-form" :class="$style['bill-details-form']">
    <LoadingScreen v-if="isLoading" />
    <div v-else>
      <VeeForm
        ref="form"
        class="c-form"
        :class="$style.form"
        :validation-schema="schemaToUse"
        :initial-values="{
          beneficiaryId: activeBeneficiary,
          receiptVendorName: receiptVendorName,
          calendar: isReceiptFlow ? receiptDate : invoiceDueDate,
          memo,
        }"
        @submit="onSubmit"
      >
        <div v-if="showWarning" class="c-form__row grid">
          <Message v-if="pastDueDateWarning" severity="warn" :closable="false">
            <p>
              Invoices cannot be more than 60 days past due.
              <a
                href="https://help.clear.co/s/article/How-to-submit-an-invoice"
                target="_blank"
                :class="$style.link"
                >Learn more about eligibility</a
              >
            </p>
          </Message>
          <Message v-if="threeDaysWarning" severity="warn" :closable="false">
            <p>
              {{
                $t('account.pages.pageVendors.billDetailsForm.dueDateWarning')
              }}
              <a
                :class="$style.link"
                :href="
                  addLocaleParam(
                    'https://help.clear.co/s/article/Invoice-processing-times',
                  )
                "
                target="_blank"
              >
                {{
                  $t(
                    'account.pages.pageVendors.billDetailsForm.dueDateWarningLinkText',
                  )
                }}
              </a>
            </p>
          </Message>
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6">
            <DropdownGroup
              class="vendor-selection"
              :label="$t('common.vendor')"
              :placeholder="$t('common.selectVendor')"
              name="beneficiaryId"
              :options="vendorOptions"
              option-label="name"
              append-to="body"
              :filter="true"
              :reset-filter-on-hide="true"
              @change="switchVendor"
            >
              <template #option="slotProps">
                <div
                  :class="{
                    'dropdown-separation-line': slotProps.option.isLastItem,
                  }"
                >
                  <div>
                    {{ slotProps.option.name }}
                  </div>
                  <div v-if="slotProps.option.accountNumberLast4">
                    {{ slotProps.option.accountNumberLast4.type }}
                    {{ slotProps.option.accountNumberLast4.value }}
                    ({{ slotProps.option.currencyCode }})
                  </div>
                </div>
              </template>
            </DropdownGroup>
          </div>
          <div v-if="userSelectedOther" class="col-span-6">
            <InputTextGroup
              v-model="receiptVendorName"
              name="receiptVendorName"
              class="col-span-6"
              type="text"
              :label="
                $t('account.pages.pageVendors.billDetailsForm.vendorName')
              "
              @blur="updateVendorName(receiptVendorName)"
            />
          </div>
          <div class="col-span-6">
            <CalendarGroup
              :label="
                isReceiptFlow
                  ? $t(
                      'account.pages.pageVendors.billDetailsForm.receiptPaidDate',
                    )
                  : $t(
                      'account.pages.pageVendors.billDetailsForm.invoiceDueDate',
                    )
              "
              name="calendar"
              :manual-input="false"
              :max-date="isReceiptFlow ? today : null"
              :min-date="isReceiptFlow ? minReceiptDate : today"
              show-icon
              @date-select="updateDueDate"
            >
              <template #label>
                <button
                  v-tooltip.top.focus="{
                    value: isReceiptFlow
                      ? `Please select the date when the vendor was paid, not your card statement billing date.`
                      : `Please select the date you would like the vendor to receive funds`,
                    escape: true,
                  }"
                  type="button"
                  :class="['pi pi-info-circle', $style.tooltip]"
                />
              </template>
            </CalendarGroup>
          </div>
        </div>
        <div v-if="userSelectedOther" :class="$style['other_vendor_msg']">
          <Message severity="info" :closable="false">
            <p>
              <i18n-t
                keypath="account.pages.pageVendors.billDetailsForm.othersVendorsInfo"
              />
            </p>
          </Message>
        </div>
        <div v-if="!userSelectedOther && !isReceiptFlow" class="c-form__row">
          <InputTextGroup
            v-model="value"
            label="Payment Reference For Recipient"
            data-cy="memo"
            name="memo"
            @blur="updateMemo(value)"
          >
            <template #label>
              <button
                v-tooltip.top.focus="{
                  value: `This information will be provided to the beneficiary bank and
                    should appear on their statement. Specific payment reference instructions
                    may have been provided by your vendor on your invoice.`,
                  escape: true,
                }"
                type="button"
                :class="['pi pi-info-circle', $style.tooltip]"
              />
            </template>
          </InputTextGroup>
        </div>
        <div class="c-form__controls">
          <DSButton
            :label="$t('account.pages.pageVendors.goBack')"
            class="p-button-link"
            :class="$style['back-button']"
            @click="goBack"
          />
          <DSButton type="submit">{{
            $t('account.pages.pageVendors.nextStep')
          }}</DSButton>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm } from 'vee-validate';
import { object, string, date } from 'yup';
import { validateChildren } from '@/composables/validation';
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';
import LoadingScreen from '@/components/LoadingScreen';
import analytics from '@/utils/analytics';
import {
  MIN_RECEIPT_PAID_DATE_IN_DAYS,
  CLEARPAY_SEGMENT_EVENTS,
  DEFAULT_CURRENCY,
} from '@/data/payments';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import CalendarGroup from '@clearbanc/clear-components/calendargroup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import addLocaleParam from '@/utils/add-locale-param';
import { SEGMENT_EVENTS } from '../../data/segment-events';

export default {
  components: {
    LoadingScreen,
    Message,
    VeeForm,
    DSButton,
    InputTextGroup,
    DropdownGroup,
    CalendarGroup,
  },
  props: {
    businessId: {
      type: Number,
      required: true,
    },
    dueDate: {
      type: [String, Date],
      default: '',
    },
    issuedDate: {
      type: [String, Date],
      default: '',
    },
    beneficiaryId: {
      type: String,
      default: '',
    },
    amountCents: {
      type: Number,
      required: true,
    },
    selectedCurrency: {
      type: String,
      required: true,
    },
    memo: {
      type: String,
      required: true,
    },
    receiptVendorName: {
      type: String,
    },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      isLoading: false,
      showErrors: false,
      invoiceDueDate: this.dueDate
        ? new Date(this.convertToUtcDate(this.dueDate))
        : null,
      receiptDate: this.issuedDate
        ? new Date(this.convertToUtcDate(this.issuedDate))
        : null,
      today: new Date(),
      minReceiptDate: dateFns.subDays(
        new Date(),
        MIN_RECEIPT_PAID_DATE_IN_DAYS,
      ),
      receiptSchema: object({
        beneficiaryId: object()
          .test(
            'currencyMatch',
            'The currency for the selected vendor must match the invoice.',
            (val) => {
              if (!val || !val.currencyCode) return true;
              return this.selectedCurrency === val.currencyCode;
            },
          )
          .required('Vendor is required'),
        calendar: date()
          .nullable()
          .required('Date paid is required')
          .min(
            dateFns.subDays(new Date(), MIN_RECEIPT_PAID_DATE_IN_DAYS),
            `Receipt must be issued within last ${MIN_RECEIPT_PAID_DATE_IN_DAYS} days`,
          ),
      }),
      otherVendorReceiptSchema: object({
        beneficiaryId: object()
          .test(
            'currencyMatch',
            'The currency for the selected vendor must match the invoice.',
            (val) => {
              if (!val || !val.currencyCode) return true;
              return this.selectedCurrency === val.currencyCode;
            },
          )
          .required('Vendor is required'),
        receiptVendorName: string()
          .nullable()
          .required('Vendor name is required'),
        calendar: date()
          .nullable()
          .required('Date paid is required')
          .min(
            dateFns.subDays(new Date(), MIN_RECEIPT_PAID_DATE_IN_DAYS),
            `Receipt must be issued within last ${MIN_RECEIPT_PAID_DATE_IN_DAYS} days`,
          ),
      }),
      invoiceSchema: object({
        beneficiaryId: object()
          .test(
            'currencyMatch',
            'The currency for the selected vendor must match the invoice.',
            (val) => {
              if (!val || !val.currencyCode) return true;
              return this.selectedCurrency === val.currencyCode;
            },
          )
          .required('Vendor is required'),
        calendar: date()
          .nullable()
          .required('Invoice date is required')
          .min(dateFns.subDays(new Date(), 1), `Invoice cannot be past due`),
        memo: string()
          .required('Payment reference/memo is required')
          .max(70)
          .matches(
            /^[a-zA-Z0-9 ]*$/,
            'Payment reference must contain alphanumerical characters only',
          ),
      }),
      otherVendorInvoiceSchema: object({
        beneficiaryId: object()
          .test(
            'currencyMatch',
            'The currency for the selected vendor must match the invoice.',
            (val) => {
              if (!val || !val.currencyCode) return true;
              return this.selectedCurrency === val.currencyCode;
            },
          )
          .required('Vendor is required'),
        receiptVendorName: string(),
      }),
    };
  },
  computed: {
    ...mapGetters([
      'beneficiaries',
      'isReceiptFlow',
      'receiptVendorOptions',
      'isDiligenceServiceReadDataEnabled',
      'getV2BeneficiaryById',
      'isDeprecateV0BeneficiariesEnabled',
    ]),
    // The following populates the available list of Beneficiaries the user can select
    // from during the Invoice upload flow.
    vendorOptions() {
      if (this.isReceiptFlow) {
        const vendorBeneOptions = [];
        for (const beneficiary of this.beneficiaries) {
          // eslint-disable-next-line no-continue
          if (!beneficiary.beneficiaryServiceId) continue;

          // `this.beneficiaries` is a list of v0 beneficiaries. We need to get the v2 beneficiary to check if it's approved.
          const v2Beneficiary = this.getV2BeneficiaryById(
            beneficiary.beneficiaryServiceId,
          );

          // eslint-disable-next-line no-continue
          if (!v2Beneficiary) continue;

          if (
            v2Beneficiary.localDiligenceStatus === 'approved' ||
            v2Beneficiary.priorityDiligenceStatus === 'approved'
          ) {
            vendorBeneOptions.push({
              name: this.isDeprecateV0BeneficiariesEnabled
                ? beneficiary.label
                : beneficiary.name,
              value: beneficiary.id,
              // This is a flag to determine if the current beneficiary is the last item in the list and add a separation line in the dropdown
              isLastItem: false,
              ...this.beneDropdownDetails(beneficiary),
            });
          }
        }

        if (vendorBeneOptions.length) {
          vendorBeneOptions[vendorBeneOptions.length - 1].isLastItem = true;
        }

        return [...vendorBeneOptions, ...this.receiptVendorOptions];
      }

      const vendorBeneOptions = this.beneficiaries.map((beneficiary) => ({
        name: this.isDeprecateV0BeneficiariesEnabled
          ? beneficiary.label
          : beneficiary.name,
        value: beneficiary.id,
        ...this.beneDropdownDetails(beneficiary),
      }));

      return [
        { name: this.$t('common.newVendor'), value: '+newvendor' },
        ...vendorBeneOptions,
      ];
    },
    userSelectedOther() {
      return this.beneficiaryId === 'other';
    },
    showWarning() {
      return this.threeDaysWarning || this.pastDueDateWarning;
    },
    isDueDateSelected() {
      return this.invoiceDueDate && this.invoiceDueDate !== '';
    },
    pastDueDateWarning() {
      return (
        !this.isReceiptFlow &&
        this.isDueDateSelected &&
        dateFns.isBefore(
          new Date(this.invoiceDueDate),
          new Date().setHours(0, 0, 0, 0),
        )
      );
    },
    threeDaysWarning() {
      // show warning if due date is not today + 3 days
      return (
        this.isDueDateSelected &&
        !this.pastDueDateWarning &&
        dateFns.differenceInBusinessDays(
          new Date(this.invoiceDueDate),
          new Date(),
        ) <= 3 &&
        !this.isReceiptFlow
      );
    },
    activeBeneficiary() {
      return this.vendorOptions.find(
        (option) => option.value === this.beneficiaryId,
      );
    },
    schemaToUse() {
      if (this.userSelectedOther) {
        if (this.isReceiptFlow) {
          return this.otherVendorReceiptSchema;
        }
        return this.otherVendorInvoiceSchema;
      } else if (this.isReceiptFlow) {
        return this.receiptSchema;
      }
      return this.invoiceSchema;
    },
  },
  methods: {
    addLocaleParam,
    convertToUtcDate(_date) {
      const dateUtc = new Date(_date)
        .toISOString()
        .replace('T', ' ')
        .substring(0, 19);
      return dateFns.parseISO(dateUtc);
    },
    updateDueDate(value) {
      if (this.isReceiptFlow) {
        this.receiptDate = value;
        this.$emit('issuedDate', this.formatDate(new Date(value)));
      } else {
        this.invoiceDueDate = value;
        this.$emit('dueDate', this.formatDate(new Date(value)));
      }
    },
    updateVendorName(value) {
      this.$emit('update-receipt-vendor', value, this.userSelectedOther);
    },
    updateMemo(value) {
      this.$emit('update-memo', value);
    },
    formatDate(_date) {
      const year = _date.getFullYear();
      const month = `0${_date.getMonth() + 1}`.slice(-2);
      const day = `0${_date.getDate()}`.slice(-2);

      return `${year}-${month}-${day}`;
    },
    switchVendor({ value: beneficiaryObject }) {
      this.$emit('beneficiaryId', beneficiaryObject.value);
      if (this.isReceiptFlow) this.updateVendorName(beneficiaryObject.name);
    },
    goBack() {
      analytics.track(SEGMENT_EVENTS.if_vendor_details_go_back_clicked);
      this.$emit('prev-tab');
    },
    async onSubmit(values) {
      if (this.userSelectedOther) {
        analytics.track(SEGMENT_EVENTS.if_receipt_other_vendor_requested, {
          receiptVendorName: this.receiptVendorName,
        });
        this.updateVendorName(this.receiptVendorName);
      }
      analytics.track(SEGMENT_EVENTS.if_vendor_details_next_step_clicked, {
        vendorId: values.beneficiaryId,
        otherVendor: this.userSelectedOther,
        receiptVendorName: this.receiptVendorName,
      });
      await this.$store.dispatchApiAction('GET_FEES_SCHEDULE', {
        dueDate: this.isReceiptFlow ? `${new Date()}` : this.invoiceDueDate,
        amountCents: this.amountCents,
        fromCurrency: this.selectedCurrency,
        toCurrency: DEFAULT_CURRENCY,
      });
      this.$emit('update-repayment-schedule');
      this.$emit('next-tab');
      this.updateMemo(this.memo);
    },
    accountNumberLast4(beneficiary) {
      let type, value;
      if (beneficiary.accountNumber) {
        type = 'Account';
        value = beneficiary.accountNumber;
      } else if (beneficiary.iban) {
        type = 'IBAN';
        value = beneficiary.iban;
      }

      return value && { type, value: `******${value.slice(-4)}` };
    },
    beneDropdownDetails(beneficiary) {
      // If deprecate v0 Beneficiaries is enabled; the beneficiary state objects are
      // actually v2 beneficiary records. Therefore we don't need to do anything special.
      if (this.isDeprecateV0BeneficiariesEnabled) {
        return {
          accountNumberLast4: this.accountNumberLast4(beneficiary),
          currencyCode: beneficiary.currencyCode,
        };
      }

      // Remove the following business logic when deprecate v0 beneficiaries feature flag is deprecated.
      let v2Beneficiary;
      if (beneficiary.beneficiaryServiceId) {
        v2Beneficiary = this.getV2BeneficiaryById(
          beneficiary.beneficiaryServiceId,
        );
      } else if (beneficiary.v2BenePayload) {
        v2Beneficiary = beneficiary.v2BenePayload;
      } else {
        return {};
      }

      return {
        accountNumberLast4: this.accountNumberLast4(v2Beneficiary),
        currencyCode: v2Beneficiary.currencyCode,
      };
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatchApiAction('GET_RECEIPT_VENDOR_OPTIONS');
    if (this.isDeprecateV0BeneficiariesEnabled) {
      // get all active beneficiaries
      await this.$store.dispatchApiAction('GET_BENEFICIARIES', {
        status: 'active',
        type: 'vendor',
      });
    } else {
      await this.$store.dispatchApiAction('GET_BILL_BENEFICIARIES');

      // TODO: Fetch batch of v2 beneficiaries for the given business once that functionality is added as part of decoupling bills-svc from benes
      await Promise.all(
        this.beneficiaries.map((bene) =>
          bene.beneficiaryServiceId
            ? this.$store.dispatchApiAction('GET_V2_BENEFICIARY', {
                beneficiaryV2Id: bene.beneficiaryServiceId,
              })
            : undefined,
        ),
      );
    }

    this.isLoading = false;
  },
  watch: {
    dueDate(newDate) {
      analytics.track(CLEARPAY_SEGMENT_EVENTS.FE_DATE_PICKED, {
        submissionDate: this.convertToUtcDate(new Date()),
        invoiceDueDate: this.convertToUtcDate(newDate),
      });
      if (this.pastDueDateWarning) {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.FE_DATE_ERROR, {
          dateOfAction: this.convertToUtcDate(new Date()),
          datePicked: this.convertToUtcDate(newDate),
          type: 'invoice',
          error: 'Invoice is past due date',
        });
      } else if (this.threeDaysWarning) {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.FE_DATE_ERROR, {
          dateOfAction: this.convertToUtcDate(new Date()),
          datePicked: this.convertToUtcDate(newDate),
          type: 'invoice',
          error: 'Payment may arrive after invoice due date',
        });
      }
    },
    issuedDate(newDate) {
      analytics.track(CLEARPAY_SEGMENT_EVENTS.FE_DATE_PICKED, {
        submissionDate: this.convertToUtcDate(new Date()),
        receiptIssuedDate: this.convertToUtcDate(newDate),
      });
      if (
        dateFns.isBefore(
          dateFns.subDays(new Date(), this.dueDate),
          dateFns.subDays(new Date(), MIN_RECEIPT_PAID_DATE_IN_DAYS),
        )
      ) {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.FE_DATE_ERROR, {
          dateOfAction: this.convertToUtcDate(new Date()),
          datePicked: this.convertToUtcDate(newDate),
          type: 'receipt',
          error: `Receipt must be issued within ${MIN_RECEIPT_PAID_DATE_IN_DAYS} days of submission`,
        });
      }
    },
  },
};
</script>

<style>
.vendor-selection .p-dropdown-label {
  text-transform: none;
}
.dropdown-separation-line {
  margin-bottom: -12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dbdbdb;
}
</style>

<style lang="less" module>
.heading {
  margin-bottom: 2rem;
  p {
    margin: 0;
  }
}

.form {
  text-align: left;
  padding: 0;
}

.error {
  margin: 0.5rem 0 0;
  font-weight: 400;
}

.other_vendor_msg {
  margin-top: 2rem;
}

button.back-button {
  margin-right: auto;
  padding: 0;
}

.error {
  margin-top: 0.5rem;
  font-weight: 400;
}

.icon {
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.tooltip {
  color: @color-black;
  padding: 4px;
  cursor: help;
}

.link {
  color: #145bce;
}

@media only screen and (min-width: 768px) {
  .bill-details-form {
    min-width: 560px;
  }
}
</style>
