// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_RbOCZ[data-v-11a6e9ad]{padding:20px;margin-top:40px;border:1px solid #e8e8ea;box-shadow:0 2px 8px 0 #0000000f;background:#fff}.header_v_Qou[data-v-11a6e9ad]{display:flex;flex-direction:row;align-items:center;margin-bottom:40px}.back-button_neCSp[data-v-11a6e9ad]{margin-right:10px}.title_ixXAJ[data-v-11a6e9ad]{color:var(--neutral-black,#000);font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:20px;font-style:normal;font-weight:500;line-height:28px;margin:0}.actions_x1bpC[data-v-11a6e9ad],.footer_sJl7T[data-v-11a6e9ad]{margin-top:40px}.actions__button_oWTvu[data-v-11a6e9ad]{width:100%}.actions__button_oWTvu+.actions__button_oWTvu[data-v-11a6e9ad]{margin-top:16px}@media (min-width:800px){.container_RbOCZ[data-v-11a6e9ad]{padding:40px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_RbOCZ",
	"header": "header_v_Qou",
	"back-button": "back-button_neCSp",
	"title": "title_ixXAJ",
	"actions": "actions_x1bpC",
	"footer": "footer_sJl7T",
	"actions__button": "actions__button_oWTvu"
};
export default ___CSS_LOADER_EXPORT___;
