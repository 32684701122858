<template>
  <div
    :class="{
      [$style['container']]: true,
      [$style['load-all-platforms']]: loadAllPlatforms,
    }"
  >
    <div>
      <LayoutAccountConnector
        :class="layoutClasses"
        :hide-tiles="!morePlatformsLoaded"
      >
        <template v-for="tile in tiles">
          <component
            :is="tile.component"
            v-if="tile.display"
            v-bind="tile.props"
            v-on="tile.events"
          ></component>
        </template>
      </LayoutAccountConnector>
      <DSButton
        v-if="!loadAllPlatforms || isMobile"
        :label="
          morePlatformsLoaded
            ? $t('common.buttons.seeLess')
            : $t('common.buttons.seeMore')
        "
        class="p-button-link"
        :class="$style['list-toggle']"
        @click="handleSeeMoreClick"
      />

      <woo-commerce-connect-payment ref="wooCommercePaymentConnector" />

      <slot name="cta" />
      <OauthConnect
        ref="oauthConnect"
        :provider-config="OAUTH_PROVIDER_CONFIG"
        @redirect="handleRedirect"
        @code="oauthSuccess"
        @connectPayment="openPaymentPopup"
        @error="handleOauthError"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { OAUTH_PROVIDER_CONFIG } from '@/data/oauth-provider-config';
import { CONNECTION_STATUS } from '@/data/connection-types';
import OauthConnect from '@/components/OauthConnect';
import analytics from '@/utils/analytics';
import { trackLinkedin } from '@/utils/conversion-tracking';
import { mqlDefinition } from '@/utils/gtm';
import { useMobileDetection } from '@/composables/responsive';
import { clickTrackingMixin, modalsRedirectMixin } from '@/utils/vue-mixins';
import { SALES_PLATFORMS } from '@/data/platforms';
import { INTEGRATION_TYPES } from '@/data/onboarding';
import {
  ExtAccount,
  ConnectAmazonSPAPI,
  ConnectAmazonVendorSPAPI,
  ConnectShopify,
  ConnectShopifyFivetran,
  ConnectWoocommerce,
} from '@/components';
import { segmentErrorTracking } from '@/utils/analytics-error-handling';
import externalAccountsComposables from '@/composables/external-accounts';
import DSButton from '@clearbanc/clear-components/button';
import WooCommerceConnectPayment from '@/components/connect/ConnectPaymentWooCommerce';
import LayoutAccountConnector from '@/components/layouts/LayoutAccountConnector';
import { useContextRoot } from '@/utils/context-root';

export default {
  components: {
    ExtAccount,
    ConnectAmazonSPAPI,
    ConnectAmazonVendorSPAPI,
    ConnectShopify,
    ConnectShopifyFivetran,
    ConnectWoocommerce,
    OauthConnect,
    WooCommerceConnectPayment,
    DSButton,
    LayoutAccountConnector,
  },
  mixins: [clickTrackingMixin, modalsRedirectMixin],
  props: {
    loadAllPlatforms: {
      type: Boolean,
      default: false,
    },
    showError: { type: Boolean, default: false },
    errorMessage: { type: String, required: false },
    eventTrackingFields: { type: Object, default: () => {} },
    showOnlyPaymentsCapacityConnections: { type: Boolean },
  },
  setup(props, context) {
    const {
      errors,
      showNextStepsError,
      updateTileToErrorStatus,
      handleTileClick,
      handleOauthError,
      trackErrorBanner,
      trackNewPlatformModal,
      trackModal,
      trackRedirect,
      getExtAccStatus,
      trackSeeMoreClick,
      findShopifyAccStatus,
    } = externalAccountsComposables(useContextRoot());
    const { isMobile } = useMobileDetection();

    return {
      isMobile,
      errors,
      showNextStepsError,
      updateTileToErrorStatus,
      handleTileClick,
      handleOauthError,
      trackErrorBanner,
      trackNewPlatformModal,
      trackModal,
      trackRedirect,
      getExtAccStatus,
      trackSeeMoreClick,
      findShopifyAccStatus,
    };
  },
  data() {
    return {
      platformType: 'sales',
      morePlatformsLoaded: false,
      paymentsCapacityConnections: this.showOnlyPaymentsCapacityConnections,
    };
  },
  computed: {
    ...mapGetters([
      'externalAccounts',
      'newSignupParams',
      'business',
      'productSegmentLabel',
      'oauthPayload',
      'showShopifyFivetranTile',
    ]),
    OAUTH_PROVIDER_CONFIG: () => OAUTH_PROVIDER_CONFIG,
    amazonLogo: () => require('@/assets/logos/amazon.png'),
    amazonVendorLogo: () => require('@/assets/logos/amazon_vendor_central.png'),
    stripeLogo: () => require('@/assets/logos/stripe.png'),
    squareLogo: () => require('@/assets/logos/square.png'),
    authorizeNetLogo: () => require('@/assets/logos/authorizenet.png'),
    paypalLogo: () => require('@/assets/logos/paypal.png'),
    braintreeLogo: () => require('@/assets/logos/braintree.png'),
    bigcommerceLogo: () => require('@/assets/logos/bigcommerce.png'),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
    INTEGRATION_TYPES: () => INTEGRATION_TYPES,
    ...mapRequestStatuses({
      oauthConnectionRequest: 'OAUTH_CONNECT',
      shopifyFivetranConfigureRequest: 'CONNECTED_ACCOUNT_CONFIGURE',
    }),
    tiles() {
      const tiles = [
        {
          key: 'paypal',
          component: 'ExtAccount',
          display: true,
          modalType: SALES_PLATFORMS.PAYPAL,
          events: {
            click: () => this.openModal(SALES_PLATFORMS.PAYPAL),
          },
          props: {
            title: 'paypal',
            logo: this.paypalLogo,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.PAYPAL) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.PAYPAL) ===
              CONNECTION_STATUS.DISCONNECTED,
            error: this.errors.paypal,
            class: this.$style.connector,
            'logo-alt': this.$t('components.tileGroupConnectSales.payPalLogo'),
          },
        },
        {
          key: 'shopify',
          component: 'ConnectShopify',
          display: !this.showShopifyFivetranTile,
          events: {
            error: () => this.updateTileToErrorStatus(SALES_PLATFORMS.SHOPIFY),
            submit: (config) =>
              this.$refs.oauthConnect.launch('shopify', config),
            modalAction: this.handleModalAction,
          },
          props: {
            ref: 'shopify',
            'authorize-uri': OAUTH_PROVIDER_CONFIG.shopify.authorizeUri,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.SHOPIFY) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.SHOPIFY) ===
              CONNECTION_STATUS.DISCONNECTED,
            error: this.errors.shopify,
            class: this.$style.connector,
          },
        },
        {
          key: 'shopify_fivetran',
          component: 'ConnectShopifyFivetran',
          display: this.showShopifyFivetranTile,
          events: {
            error: (errorMsg) => this.handleShopifyFivetranError(errorMsg),
            submit: (config) =>
              this.$refs.oauthConnect.launch('shopify_fivetran', config),
            modalAction: this.handleModalAction,
          },
          props: {
            ref: 'shopify_fivetran',
            'authorize-uri':
              OAUTH_PROVIDER_CONFIG.shopify_fivetran.authorizeUri,
            success:
              this.findShopifyAccStatus() === CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.findShopifyAccStatus() === CONNECTION_STATUS.DISCONNECTED,
            warning: this.findShopifyAccStatus() === CONNECTION_STATUS.WARNING,
            processing:
              this.findShopifyAccStatus() === CONNECTION_STATUS.PROCESSING,
            error: this.errors.shopify_fivetran,
            class: this.$style.connector,
          },
        },
        {
          key: 'stripe',
          component: 'ExtAccount',
          display: true,
          events: {
            click: () => this.openModal(SALES_PLATFORMS.STRIPE),
          },
          props: {
            title: 'stripe',
            logo: this.stripeLogo,
            'small-logo': true,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.STRIPE) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.STRIPE) ===
              CONNECTION_STATUS.DISCONNECTED,
            error: this.errors.stripe,
            class: this.$style.connector,
            'logo-alt': this.$t('components.tileGroupConnectSales.stripLogo'),
          },
        },
        {
          key: 'square',
          component: 'ExtAccount',
          display: true,
          events: {
            click: () => this.openModal(SALES_PLATFORMS.SQUARE),
          },
          props: {
            title: 'square',
            logo: this.squareLogo,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.SQUARE) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.SQUARE) ===
              CONNECTION_STATUS.DISCONNECTED,
            error: this.errors.square,
            class: this.classes,
            'logo-alt': 'Square logo',
          },
        },
        {
          key: 'amazon',
          component: 'ConnectAmazonSPAPI',
          display: true,
          events: {
            error: () => this.updateTileToErrorStatus(SALES_PLATFORMS.AMAZON),
            success: this.trackAccountConnected,
            oauthError: this.handleOauthError,
            modalAction: this.handleModalAction,
            submit: (config) =>
              this.$refs.oauthConnect.launch('amazon', config),
          },
          props: {
            ref: 'amazon',
            title: 'Amazon',
            logo: this.amazonLogo,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.AMAZON) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.AMAZON) ===
              CONNECTION_STATUS.DISCONNECTED,
            errors: this.errors,
            class: this.$style.connector,
            'corp-country': this.business.corpCountry,
          },
        },
        {
          key: 'amazonVendor',
          component: 'ConnectAmazonVendorSPAPI',
          display: true,
          events: {
            error: () =>
              this.updateTileToErrorStatus(SALES_PLATFORMS.AMAZONVENDOR),
            success: this.trackAccountConnected,
            oauthError: this.handleOauthError,
            modalAction: this.handleModalAction,
            submit: (config) =>
              this.$refs.oauthConnect.launch('amzvendor', config),
          },
          props: {
            ref: 'amazon',
            title: 'Amazon Vendor',
            logo: this.amazonVendorLogo,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.AMAZONVENDOR) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.AMAZONVENDOR) ===
              CONNECTION_STATUS.DISCONNECTED,
            errors: this.errors,
            class: this.$style.connector,
            'corp-country': this.business.corpCountry,
          },
        },
        {
          key: 'authorizenet',
          component: 'ExtAccount',
          display:
            this.showPaymentCapacityConnections ||
            this.isSuccessOrDisconnected(SALES_PLATFORMS.AUTHORIZENET),
          events: {
            click: () => this.openModal(SALES_PLATFORMS.AUTHORIZENET),
          },
          props: {
            title: 'authorizenet',
            logo: this.authorizeNetLogo,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.AUTHORIZENET) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.AUTHORIZENET) ===
              CONNECTION_STATUS.DISCONNECTED,
            error: this.errors.authorizenet,
            class: this.classes,
            'logo-alt': 'AuthorizeNet logo',
          },
        },
        {
          key: 'bigcommerce',
          component: 'ExtAccount',
          display:
            this.showPaymentCapacityConnections ||
            this.isSuccessOrDisconnected(SALES_PLATFORMS.BIGCOMMERCE),
          events: {
            click: () => this.openModal(SALES_PLATFORMS.BIGCOMMERCE),
          },
          props: {
            title: 'bigcommerce',
            logo: this.bigcommerceLogo,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.BIGCOMMERCE) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.BIGCOMMERCE) ===
              CONNECTION_STATUS.DISCONNECTED,
            error: this.errors.bigcommerce,
            class: this.classes,
            'logo-alt': 'BigCommerce logo',
          },
        },
        {
          key: 'braintree',
          component: 'ExtAccount',
          display:
            this.showPaymentCapacityConnections ||
            this.isSuccessOrDisconnected(SALES_PLATFORMS.BRAINTREE),
          events: {
            click: () => this.openModal(SALES_PLATFORMS.BRAINTREE),
          },
          props: {
            title: 'braintree',
            logo: this.braintreeLogo,
            success:
              this.getExtAccStatus(SALES_PLATFORMS.BRAINTREE) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.BRAINTREE) ===
              CONNECTION_STATUS.DISCONNECTED,
            error: this.errors.braintree,
            class: this.classes,
            'logo-alt': 'Braintree logo',
          },
        },
        {
          key: 'woocommerce',
          component: 'ConnectWoocommerce',
          display: false,
          events: {
            error: () =>
              this.updateTileToErrorStatus(SALES_PLATFORMS.WOOCOMMERCE),
            submit: (config) =>
              this.$refs.oauthConnect.launch(
                SALES_PLATFORMS.WOOCOMMERCE,
                config,
              ),
            modalAction: this.handleModalAction,
          },
          props: {
            ref: 'woocommerce',
            success:
              this.getExtAccStatus(SALES_PLATFORMS.WOOCOMMERCE) ===
              CONNECTION_STATUS.CONNECTED,
            disconnected:
              this.getExtAccStatus(SALES_PLATFORMS.WOOCOMMERCE) ===
              CONNECTION_STATUS.DISCONNECTED,
            errors: this.errors,
            class: this.classes,
          },
        },
        {
          key: 'other',
          component: 'ExtAccount',
          display: this.showPaymentCapacityConnections,
          events: {
            noPlatformModalAction: (action, platformName) =>
              this.trackNewPlatformModal(
                this.eventTrackingFields,
                action,
                INTEGRATION_TYPES.SALES,
                platformName,
              ),
          },
          props: {
            class: this.classes,
            isMissingPlatformTile: true,
          },
        },
      ];

      return this.sortTilesByStatus(tiles);
    },
    isMQL() {
      return mqlDefinition(
        this.newSignupParams.businessCountry,
        this.newSignupParams.businessMonthlyRevenue,
      );
    },
    shouldDisplayAllPlatforms() {
      return this.loadAllPlatforms || this.morePlatformsLoaded;
    },
    showPaymentCapacityConnections() {
      return !this.paymentsCapacityConnections || this.morePlatformsLoaded;
    },
    classes() {
      return {
        [this.$style.connector]: true,
        [this.$style['connector-mobile']]: this.shouldDisplayAllPlatforms,
      };
    },
    layoutClasses() {
      return {
        [this.$style.connector]: true,
      };
    },
    productLabel() {
      if (this.productSegmentLabel)
        return this.productSegmentLabel
          .replace(/_/g, ' ')
          .replace(/(?: |\b)(\w)/g, function (key, p1) {
            return key.toUpperCase();
          });
      return '';
    },
    CONNECTION_STATUS: () => CONNECTION_STATUS,
  },
  watch: {
    showNextStepsError(newVal) {
      this.$emit('update:showError', newVal);
    },
  },
  async mounted() {
    if (this.oauthPayload?.code || this.oauthPayload?.id) {
      await this.oauthSuccess(this.oauthPayload);
    } else if (this.oauthPayload?.error) {
      this.handleOauthError(this.oauthPayload);
    }
    this.$store.dispatch('SET_OAUTH_PAYLOAD', null);
    localStorage.removeItem('oauth-provider');
  },
  methods: {
    handleSeeMoreClick() {
      this.trackSeeMoreClick(this.eventTrackingFields);
      this.morePlatformsLoaded = !this.morePlatformsLoaded;
    },
    handleModalAction(platformName, action) {
      this.trackModal(
        platformName,
        action,
        this.eventTrackingFields,
        this.isMobile,
      );
    },
    handleRedirect(platformName) {
      this.trackRedirect(platformName, this.eventTrackingFields);
    },
    openModal(platformName) {
      this.handleTileClick(platformName, this.eventTrackingFields);
      const oauthPlatforms = [
        SALES_PLATFORMS.STRIPE,
        SALES_PLATFORMS.PAYPAL,
        SALES_PLATFORMS.SQUARE,
        SALES_PLATFORMS.AUTHORIZENET,
        SALES_PLATFORMS.BIGCOMMERCE,
        SALES_PLATFORMS.BRAINTREE,
      ];
      if (oauthPlatforms.includes(platformName)) {
        // See the following ticket as to why Stripe is the exception:
        // https://app.shortcut.com/clearbanc/story/179963/investigate-broken-stripe-connection-flow
        if (this.isMobile || platformName === SALES_PLATFORMS.STRIPE) {
          localStorage.setItem('oauth-provider', platformName);
          localStorage.setItem('oauth-redirect-route', this.redirectRoute);
          this.$refs.oauthConnect.launch(platformName, {
            noPopup: true,
            redirectRoute: this.redirectRoute,
          });
        } else {
          this.clearOauthParams();
          this.$refs.oauthConnect.launch(platformName);
        }
      } else {
        this.clearOauthParams();
        this.$refs[platformName].openModal();
      }
    },
    async oauthSuccess(payload) {
      if (payload.provider === SALES_PLATFORMS.SHOPIFY_FIVETRAN) {
        await this.$store.dispatchApiAction(
          'CONNECTED_ACCOUNT_CONFIGURE',
          payload,
        );
        this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS');
        if (this.shopifyFivetranConfigureRequest.isError) {
          this.handleShopifyFivetranError(
            this.shopifyFivetranConfigureRequest.errorMessage,
          );
        }
      } else {
        // payload has provider, code, sometimes more depending on provider
        await this.$store.dispatch('oauthConnect', payload);
        if (this.oauthConnectionRequest.isError) {
          this.handleOauthError(payload);
          segmentErrorTracking(
            this.oauthConnectionRequest.errorMessage,
            this.$route.name,
          );
          return;
        }
      }

      this.trackAccountConnected(payload.provider);
    },
    trackAccountConnected(platformName) {
      let segmentEventName = 'fe_account_connected';
      if (this.isMQL && this.externalAccounts.length === 1) {
        segmentEventName = 'fe_mql_account_connected';
      }
      trackLinkedin(CONNECTION_STATUS.CONNECTED);
      analytics.track(segmentEventName, {
        category: 'Connect',
        label: this.productLabel,
        page: this.$route.name,
        accountType: this.platformType,
        ...this.eventTrackingFields,
      });

      analytics.track(`fe_${this.platformType}_account_linked`, {
        fb: true,
        platform: platformName,
        ...this.eventTrackingFields,
      });
    },
    async openPaymentPopup(payload) {
      await this.$refs.wooCommercePaymentConnector.open(payload);
    },
    sortTilesByStatus(tiles) {
      const disconnected = [];
      const warning = [];
      const processing = [];
      const connected = [];
      const neutral = [];
      tiles.forEach((tile) => {
        if (tile.props.disconnected) {
          disconnected.push(tile);
        } else if (tile.props.success) {
          connected.push(tile);
        } else if (tile.props?.warning) {
          warning.push(tile);
        } else if (tile.props?.processing) {
          processing.push(tile);
        } else {
          neutral.push(tile);
        }
      });

      return [
        ...disconnected,
        ...warning,
        ...processing,
        ...connected,
        ...neutral,
      ];
    },
    isSuccessOrDisconnected(platform) {
      return (
        this.getExtAccStatus(platform) === CONNECTION_STATUS.CONNECTED ||
        this.getExtAccStatus(platform) === CONNECTION_STATUS.DISCONNECTED ||
        this.getExtAccStatus(platform) === CONNECTION_STATUS.WARNING ||
        this.getExtAccStatus(platform) === CONNECTION_STATUS.PROCESSING
      );
    },
    handleShopifyFivetranError(errorMessage) {
      this.updateTileToErrorStatus(SALES_PLATFORMS.SHOPIFY_FIVETRAN);
      this.$emit('update:errorMessage', errorMessage);
    },
    // Clear items from localStorage from previous OAuth connections
    // this is to ensure that the OAuth flow is reset
    clearOauthParams() {
      localStorage.removeItem('oauth-provider');
      localStorage.removeItem('oauth-redirect-route');
    },
  },
};
</script>

<style lang="less" module>
.container {
  margin: 20px auto 0;

  &.load-all-platforms {
    margin-top: 0;
  }
}
.connector {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.support-link {
  color: @color-red;
  text-decoration: underline;
}

button.list-toggle {
  display: block;
  margin: 30px auto;
}
</style>
