import { getCurrentInstance } from 'vue';

const DEFAULT_LIFE_IN_MS = 5000;

export function useToast(initialGroup) {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error('useToast must be used within a setup function.');
  }

  const getParams = (params) => {
    const {
      summary,
      group = initialGroup,
      life = DEFAULT_LIFE_IN_MS,
    } = params ?? {};
    return {
      summary,
      group,
      life,
    };
  };

  const addToast = (toastOptions) => {
    instance.proxy?.$toast.add(toastOptions);
  };

  const success = (detail, params) => {
    addToast({
      severity: 'success',
      detail,
      ...getParams(params),
    });
  };

  const error = (detail, params) => {
    addToast({
      severity: 'error',
      detail,
      ...getParams(params),
    });
  };

  const info = (detail, params) => {
    addToast({
      severity: 'info',
      detail,
      ...getParams(params),
    });
  };

  const warn = (detail, params) => {
    addToast({
      severity: 'warn',
      detail,
      ...getParams(params),
    });
  };

  return {
    addToast,
    success,
    error,
    info,
    warn,
  };
}
