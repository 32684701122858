export const DEFAULT_RATES = [
  {
    num_months: 4,
    rate: 0.055,
  },
  {
    num_months: 5,
    rate: 0.07,
  },
  {
    num_months: 6,
    rate: 0.09,
  },
];
