// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".application-step_sZdfo{all:unset;box-sizing:border-box;width:100%;padding:0 1.5rem;display:flex;align-items:center;justify-content:flex-start;text-align:left;border:1px solid #ceced0;border-radius:4px;box-shadow:0 2px 8px rgba(0,0,0,.06);cursor:pointer;gap:1rem;padding:1rem 1.5rem}.application-step_sZdfo .step-count_7IPyn{display:flex;color:#dadada;border:1px solid #dadada;border-radius:50%;width:1rem;height:1rem;font-size:12px;flex-shrink:0;align-items:center;justify-content:center;align-self:flex-start;margin-top:5px}.application-step_sZdfo .wrapper_sOF5o{display:flex;align-items:center;justify-content:space-between;width:100%;flex-wrap:wrap;gap:6px}.application-step_sZdfo .wrapper_sOF5o .title_xM15Y{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-weight:300;font-size:1rem;line-height:1.5rem;color:#000;text-align:left;margin:0}.application-step_sZdfo .wrapper_sOF5o .actionTag_tSJ3P{text-align:center}.application-step_sZdfo+.application-step_sZdfo,.application-step_sZdfo+[class^=p-button]{margin-top:1rem}@media only screen and (min-width:960px){.application-step_sZdfo+.application-step_sZdfo,.application-step_sZdfo+[class^=p-button]{margin:1.5rem 0 0}}.application-step_sZdfo .chevron_kDHBi{margin-left:10px;width:1rem;height:1rem;transition:all .3s ease}.application-step_sZdfo .status-icon_zMn23{display:inline-block;height:1rem;width:1rem;margin:0 1rem 0 0}.application-step_sZdfo:focus{outline:none}.application-step_sZdfo:hover .chevron_kDHBi{transform:translateX(.75rem)}.application-step--disabled_NGtnt{cursor:default;background-color:#f8f8fa}.application-step--disabled_NGtnt .title_xM15Y{color:#ababad}.application-step--disabled_NGtnt:hover .chevron_kDHBi{transform:unset}.icon_V9Kq0{display:contents}.icon_V9Kq0 svg{width:16px;height:16px;margin:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"application-step": "application-step_sZdfo",
	"step-count": "step-count_7IPyn",
	"wrapper": "wrapper_sOF5o",
	"title": "title_xM15Y",
	"actionTag": "actionTag_tSJ3P",
	"chevron": "chevron_kDHBi",
	"status-icon": "status-icon_zMn23",
	"application-step--disabled": "application-step--disabled_NGtnt",
	"icon": "icon_V9Kq0"
};
export default ___CSS_LOADER_EXPORT___;
