// A place to track all segment events one day
export const SEGMENT_EVENTS = {
  if_abandon_bill_submission: 'if_abandon_bill_submission',
  if_start_abandon_bill_submission: 'if_start_abandon_bill_submission',
  if_bill_submission_ocr_details: 'if_bill_submission_ocr_details',
  if_clicked_add_bill: 'if_clicked_add_bill',
  if_viewed_invoice_modal_add_receipt: 'if_viewed_invoice_modal_add_receipt',
  if_receipt_requirements_reviewed: 'if_receipt_requirements_reviewed',
  if_add_receipt_go_back_clicked: 'if_add_receipt_go_back_clicked',
  if_add_receipt_next_clicked: 'if_add_receipt_next_clicked',
  if_clicked_bnpl_payment_schedule_next:
    'if_clicked_bnpl_payment_schedule_next',
  if_receipt_other_vendor_requested: 'if_receipt_other_vendor_requested',
  if_vendor_details_next_step_clicked: 'if_vendor_details_next_step_clicked',
  if_vendor_details_go_back_clicked: 'if_vendor_details_go_back_clicked',
  if_bill_submitted: 'if_bill_submitted',
};
