<template>
  <div>
    <button
      v-if="!listChildrenOnly"
      :data-ref="getRef(links.title())"
      :class="parentLinkClasses"
      :aria-label="`${links.title()} ${hasChildLinks ? 'dropdown' : ''}`"
      @click="handleParentClick"
    >
      <icon
        v-if="showWarningIcon(links)"
        name="exclamation-triangle"
        :class="$style['warning-icon']"
      />
      <p :class="parentClasses(links)">
        <BaseIcon
          v-if="links.icon"
          :icon-name="links.icon"
          :class="$style['parent-icon']"
        />
        <span>{{ links.title() }}</span>
        <span v-if="!!links.subtitle" :class="$style.subtitle">
          {{ links.subtitle() }}
        </span>
      </p>
      <Tag
        v-if="showLegacyIcon"
        :class="$style['legacy-label']"
        severity="secondary"
        value="Legacy"
      />

      <span
        v-if="hasChildLinks && !forceDropdown"
        :class="$style['accordion-container']"
      >
        <accordion-button :is-inverted="displayChildLinks" />
      </span>
    </button>
    <div v-if="displayChildLinks || listChildrenOnly">
      <button
        v-for="link in links.children"
        :key="link.title()"
        :data-ref="getRef(link.title())"
        :class="childLinkClasses(link.route)"
        @click="handleChildClick(link.route)"
      >
        <p :class="childClasses(link.route)">
          <icon
            v-if="showFaIcon(link)"
            :class="childIconClasses(link)"
            :name="link.faIcon"
          />
          {{ link.title() }}
        </p>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import AccordionButton from '@/components/buttons/ButtonAccordion';
import Tag from '@clearbanc/clear-components/tag';
import { WAYFINDING_ROUTES } from '@/data/wayfinding';
import BaseIcon from './icons/base-icon';

export default {
  components: { AccordionButton, BaseIcon, Tag },
  props: {
    links: { type: Object, required: true },
    listChildrenOnly: { type: Boolean, default: false },
    openDropdown: { type: Boolean, default: false },
    forceDropdown: { type: Boolean, default: false },
    refType: { type: String, default: 'link' },
  },
  data() {
    return {
      displayChildLinks:
        !!this.openDropdown ||
        !!this.forceDropdown ||
        (this.links.forceClickable && this.isRouteMatch(this.links.route)),
    };
  },
  computed: {
    ...mapGetters([
      'bills',
      'userHasActiveBillsAdvanceAndDisconnectedSalesAccount',
      'isBusinessProfileComplete',
      'hasDisconnectedSalesAccounts',
      'salesAccountsConnected',
      'billsTotalCount',
      'debtObligationsRequired',
      'hasActiveDocumentRequests',
    ]),
    hasChildLinks() {
      return !!this.links.children?.length;
    },
    parentLinkClasses() {
      return {
        [this.$style.box]: true,
        [this.$style.selected]: this.isParentMatch(this.links),
        [this.$style.highlighted]: this.isParentMatch(this.links),
        [this.$style['with-subtitle']]: !!this.links.subtitle,
      };
    },
    hasSubmittedBill() {
      return this.bills?.length > 0;
    },
    showLegacyIcon() {
      return this.links.route === WAYFINDING_ROUTES.SPEND;
    },
    isPaymentsBizProfileIncomplete() {
      return (
        (this.hasSubmittedBill && !this.isBusinessProfileComplete) ||
        (this.hasSubmittedBill && this.debtObligationsRequired) ||
        this.userHasActiveBillsAdvanceAndDisconnectedSalesAccount
      );
    },
  },
  methods: {
    isRouteMatch(routeName) {
      return this.$route.name === routeName;
    },
    isParentMatch(link) {
      const matchingChild = link.children.some((child) =>
        this.isRouteMatch(child.route),
      );
      if (link?.matchForChildren) {
        return this.$route.name.startsWith(link?.route);
      }
      return this.isRouteMatch(link?.route) && !matchingChild;
    },
    toggleAccordion() {
      this.$emit('toggleAccordion', {
        type: this.displayChildLinks ? 'collapse' : 'expand',
        link: this.links.title(),
      });
      this.displayChildLinks = !this.displayChildLinks;
    },
    parentClasses(links) {
      return {
        [this.$style.parent]: true,
        [this.$style['selected-text']]: this.isParentMatch(links),
      };
    },
    childLinkClasses(routeName) {
      return {
        [this.$style.box]: true,
        [this.$style.reduced]: this.listChildrenOnly,
        [this.$style.selected]: this.isRouteMatch(routeName),
        [this.$style.highlighted]:
          this.isRouteMatch(routeName) && !this.listChildrenOnly,
        [this.$style.darken]: this.listChildrenOnly,
      };
    },
    childIconClasses(link) {
      const isWarningIcon = link.faIcon === 'exclamation-triangle';
      return {
        [this.$style['child-icon']]: true,
        [this.$style.warning]: isWarningIcon,
      };
    },
    childClasses(routeName) {
      return {
        [this.$style.child]: true,
        [this.$style['shift-left']]: this.listChildrenOnly,
        [this.$style['selected-text']]: this.isRouteMatch(routeName),
      };
    },
    handleChildClick(routeName) {
      const eventBadgeProps = {
        notificationBadgePresent: false,
        notificationBadgeType: null,
      };

      this.$emit('linkClicked', {
        routeName,
        eventBadgeProps,
      });
    },
    handleParentClick() {
      if (this.links.route) {
        this.$emit('linkClicked', {
          routeName: this.links.route,
        });
        if (
          this.links.forceClickable &&
          !this.openDropdown &&
          !this.forceDropdown
        ) {
          this.toggleAccordion();
        }
      } else if (!this.forceDropdown) {
        this.toggleAccordion();
      }
    },
    getRef(name) {
      return name.toLowerCase().split(' ').join('-').concat(`-${this.refType}`);
    },
    showFaIcon(link) {
      const paymentsBusinessProfileLink = Object.values(
        PAYMENTS_ROUTE_NAMES,
      ).includes(link.route);

      if (paymentsBusinessProfileLink) {
        return !!link.faIcon && this.isPaymentsBizProfileIncomplete;
      }
      return !!link.faIcon;
    },
    showWarningIcon(links) {
      switch (links.route) {
        case PAYMENTS_ROUTE_NAMES.ALL_DOCUMENTS:
          return this.hasActiveDocumentRequests;

        case PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT:
          return (
            this.billsTotalCount > 0 &&
            (!this.salesAccountsConnected || this.hasDisconnectedSalesAccounts)
          );

        default:
          return false;
      }
    },
  },
};
</script>

<style lang="less" module>
.parent {
  color: @cc-light-brown;
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
  font-family: @gerstner-font;
  font-size: @font-size-16;

  .parent-icon {
    margin-right: 8px;
  }
}
.warning-icon {
  position: absolute;
  margin: 0 0 0 8px;
  color: @color-warning-300;
}
.legacy-label {
  margin-left: 4px;
}

.box {
  position: relative;
  align-items: center;
  background: none;
  border: none;
  color: @cc-light-brown;
  cursor: pointer;
  display: flex;
  font-family: @gerstner-font;
  font-size: @font-size-16;
  height: 60px;
  width: 100%;
  padding: unset;
  &:hover {
    background: @color-white;
    @media only screen and (max-width: @wayfinding-tablet-width) {
      background: @color-lightgray-100;
    }
    &.darken {
      background: @color-lightgray-100;
    }
  }
  &.reduced {
    padding: 0 20px;
    height: 40px;
    font-size: @font-size-14;
  }

  &.with-subtitle {
    padding-bottom: 10px;
  }

  .subtitle {
    position: absolute;
    bottom: 7px;
    left: 30px;
    font-size: 10px;
    font-weight: 700;
    font-family: @gerstner-font;
    color: @color-cc-purple-200;
  }
}
.selected {
  font-weight: 700;
  &.highlighted {
    background: @color-white;
    @media only screen and (max-width: @wayfinding-tablet-width) {
      background: @color-lightgray-100;
    }
  }
}
.child {
  color: @cc-light-brown;
  cursor: pointer;
  display: flex;
  margin-left: 55px;

  &.shift-left {
    margin-left: unset;
  }

  .child-icon {
    align-self: center;
    margin-left: -20px;
    margin-right: 4px;
  }

  .warning {
    color: @color-warning-300;
  }
}
.selected-text {
  color: @color-black;
}
.accordion-container {
  cursor: pointer;
  margin-left: 5px;
  padding: 10px;
}
</style>
