const FB_AUTH_URI =
  'https://www.facebook.com/v4.0/dialog/oauth?display=popup&auth_type=reauthenticate';
const GOOGLE_AUTH_URI = 'https://accounts.google.com/o/oauth2/v2/auth';

export const OAUTH_PROVIDER_CONFIG = {
  homeaway: {
    authorizeUri: 'https://ws.homeaway.com/oauth/authorize',
    clientId: process.env.HOMEAWAY_CLIENT_ID,
    // redirectUri: '/oauth-callback',
  },
  shopify: {
    authorizeUri: 'https://www.myshopify.com/admin/oauth/authorize',
    clientId: process.env.SHOPIFY_API_KEY,
    redirectUri: '/oauth-signup?provider=shopify',
    scope: process.env.SHOPIFY_SCOPE,
  },
  shopify_fivetran: {
    redirectUri: '/oauth-callback',
    largePopup: true,
  },
  bigcommerce: {
    authorizeUri: `https://login.bigcommerce.com/app/${process.env.BIGCOMMERCE_CLIENT_ID}/install`,
    clientId: process.env.BIGCOMMERCE_CLIENT_ID,
    redirectUri: '/oauth-callback',
  },
  woocommerce: {
    // placeholder URI as mywoocommerce.com is replaced with the client's store URL
    authorizeUri: `https://mywoocommerce.com/wc-auth/v1/authorize`,
    redirectUri: '/oauth-callback',
  },
  facebook: {
    authorizeUri: FB_AUTH_URI,
    clientId: process.env.FACEBOOK_APP_ID,
    redirectUri: '/oauth-callback',
    scope: 'email,ads_read',
  },
  instagram: {
    authorizeUri: FB_AUTH_URI,
    clientId: process.env.FACEBOOK_APP_ID,
    redirectUri: '/oauth-callback',
    scope: 'email,ads_read',
  },
  googleads: {
    authorizeUri: GOOGLE_AUTH_URI,
    prompt: 'consent',
    access_type: 'offline',
    clientId: process.env.GOOGLE_OAUTH_CLIENT_ID,
    scope: 'https://www.googleapis.com/auth/adwords',
    redirectUri: '/oauth-callback',
  },
  stripe: {
    authorizeUri:
      'https://connect.stripe.com/oauth/authorize?stripe_landing=login',
    clientId: process.env.STRIPE_CLIENT_ID,
    scope: 'read_only',
    redirectUri: '/oauth-callback',
  },
  authorizenet: {
    authorizeUri:
      process.env.AUTHORIZE_NET_ENV === 'production'
        ? 'https://account.authorize.net/oauth/authorize'
        : 'https://sandbox.authorize.net/oauth/authorize',
    clientId: process.env.AUTHORIZE_NET_CLIENT_ID,
    scope: 'read',
    redirectUri: '/oauth-callback',
    sub: 'oauth',
  },
  paypal: {
    authorizeUri:
      process.env.CLEARBANC_ENV === 'production'
        ? 'https://www.paypal.com/signin/authorize'
        : 'https://www.sandbox.paypal.com/signin/authorize',
    clientId: process.env.PAYPAL_CLIENT_ID,
    redirectUri: '/oauth-callback',
    scope: 'https://uri.paypal.com/services/reporting/search/read',
    sub: 'oauth',
  },
  braintree: {
    authorizeUri: process.env.BRAINTREE_AUTHORIZEURI,
    clientId: process.env.BRAINTREE_CLIENT_ID,
    redirectUri: '/oauth-callback',
    scope:
      'read_facilitated_transactions,transaction:search,subscription:search',
    sub: 'oauth',
  },
  square: {
    authorizeUri:
      process.env.CLEARBANC_ENV === 'production'
        ? 'https://connect.squareup.com/oauth2/authorize'
        : 'https://connect.squareupsandbox.com/oauth2/authorize',
    clientId: process.env.SQUARE_APP_ID,
    redirectUri: '/oauth-callback',
    scope: 'MERCHANT_PROFILE_READ ORDERS_READ PAYMENTS_READ SETTLEMENTS_READ',
  },
  amazonads: {
    authorizeUri: 'https://www.amazon.com/ap/oa',
    clientId: process.env.AMAZON_CLIENT_ID,
    redirectUri: '/oauth-callback',
    scope: 'cpc_advertising:campaign_management',
  },
  amazon: {
    clientId: process.env.AMAZON_SPAPI_CLIENT_ID,
    appId: process.env.AMAZON_SPAPI_APP_ID,
    redirectUri: '/oauth-callback',
  },
  amzvendor: {
    clientId: process.env.AMAZON_SPAPI_CLIENT_ID,
    appId: process.env.AMAZON_SPAPI_APP_ID,
    redirectUri: '/oauth-callback',
  },
  // for google-login
  google: {
    apiUrl: 'https://apis.google.com/js/api.js',
    scope: 'https://www.googleapis.com/auth/user.phonenumbers.read',
    peopleApiDiscoveryDocs: 'https://people.googleapis.com/$discovery/rest',
  },
};
