const onlyNumbers = {
  beforeMount(el, binding) {
    if (binding.value === false) {
      return;
    }
    el.addEventListener('input', () => {
      const input = el.tagName === 'INPUT' ? el : el.querySelector('input');
      if (!input) return;

      const sanitizedValue = input.value.replace(/[^0-9]/g, '');

      if (input.value !== sanitizedValue) {
        input.value = sanitizedValue;
        input.dispatchEvent(
          new InputEvent('input', { bubbles: true, cancelable: true }),
        );
      }
    });
  },
};

export default onlyNumbers;
