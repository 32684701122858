<template>
  <LayoutFormPage :show-error-message="showError" :error-message="errorMessage">
    <template #form>
      <FormBusinessFormation
        ref="form"
        collect-registration-number
        :event-tracking-fields="commonTrackingFields"
        @validationSuccess="handleValidationSuccess"
        @validationFailure="handleValidationFailure"
        @docsUploaded="showError = false"
      />
    </template>
    <template #cta>
      <ButtonLinkContainer
        show-back-btn
        :text="$t('common.buttons.submit')"
        theme="purple"
        @previousClick="goBack"
        @nextClick="nextStep"
      />
    </template>
  </LayoutFormPage>
</template>

<script>
import analytics from '@/utils/analytics';
import stateModuleAware from '@/mixins/state-module-aware';
import { mapGetters } from 'vuex';
import { mapStateModuleGetters } from '@/utils/state-modules';
import {
  FormBusinessFormation,
  ButtonLinkContainer,
  LayoutFormPage,
} from '@/components';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

export default {
  components: {
    ButtonLinkContainer,
    FormBusinessFormation,
    LayoutFormPage,
  },
  mixins: [stateModuleAware],
  data() {
    return {
      showError: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters(['isImprovedKyFlowEnabled']),
    ...mapStateModuleGetters(['steps', 'previousStepName']),
    currentRoute() {
      return this.$route.name;
    },
    commonTrackingFields() {
      return {
        targetArea: this.currentRoute,
      };
    },
    currentStep() {
      return this.steps[this.currentRoute];
    },
  },
  methods: {
    trackEvent(eventName) {
      analytics.track(eventName, this.commonTrackingFields);
    },
    async nextStep() {
      await this.$refs.form.validateForm();
    },
    goBack() {
      this.$router.push({ name: this.previousStepName(this.currentRoute) });
    },
    async handleValidationSuccess() {
      this.dispatchToStateModule('UPDATE_STEP', {
        step: this.currentRoute,
      });
      this.$router.push({
        name: this.currentStep.nextRoute.name,
      });
    },
    handleValidationFailure(msg) {
      this.errorMessage = msg ?? '';
      this.showError = true;
    },
  },
  mounted() {
    if (this.isImprovedKyFlowEnabled) {
      this.$router.push({ name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD });
    }
  },
};
</script>
