<template>
  <div>
    <div data-cy="bnpl-payment-schedule-modal">
      <VeeForm
        ref="form"
        class="c-form"
        :class="$style.form"
        :validation-schema="isReceiptFlow ? receiptSchema : invoiceSchema"
        :initial-values="{
          amount: Number(amount) || null,
          payOutCurrency: selectedCurrency,
        }"
        @submit="updateAndContinue"
      >
        <div
          class="c-form__row grid grid-cols-12"
          :class="$style['amount-wrapper']"
        >
          <div class="col-span-7 sm:col-span-12">
            <InputNumberGroup
              :label="
                $t('account.pages.pageVendors.bnplPaymentSchedule.amount', {
                  type: handleType,
                })
              "
              name="amount"
              mode="currency"
              currency="USD"
              locale="en-US"
              placeholder="$0.00"
              @input="updateAmountDebounced"
            />
          </div>
          <div v-if="isReceiptFlow" class="col-span-5 sm:col-span-12">
            <InputTextGroup
              name="payOutCurrency"
              :label="
                $t('account.pages.pageVendors.bnplPaymentSchedule.currency')
              "
              disabled="true"
              data-cy="static-pay-out-currency"
            />
          </div>
          <div v-else class="col-span-5 sm:col-span-12">
            <DropdownGroup
              name="payOutCurrency"
              :label="
                $t('account.pages.pageVendors.bnplPaymentSchedule.currency')
              "
              option-label="value"
              option-value="value"
              :options="currencyOptions"
              append-to="self"
              data-cy="pay-out-currency"
              @change="currencyChange"
            >
              <template #input>
                <button
                  v-tooltip.focus="
                    $t(
                      'account.pages.pageVendors.bnplPaymentScheduleConfirmation.currencyToolTip',
                    )
                  "
                  type="button"
                  class="pi pi-info-circle"
                  :class="$style.tooltip"
                />
              </template>
            </DropdownGroup>
          </div>
        </div>
        <div class="c-form__row">
          <div v-if="isLoading" :class="$style['grey-container']">
            <icon name="spinner" scale="2" />
            <p>
              {{ $t('account.pages.pageVendors.bnplPaymentSchedule.loading') }}
            </p>
          </div>

          <div v-else>
            <CardsRadio
              v-if="amount && amount !== '0.00'"
              :choices="repaymentSchedule?.repaymentSchedulesByMonth"
              :position="`${termChosen}`"
              :capacity-response="businessCapacity"
              :bill-amount="amountCents"
              @getStepValue="getStepValue"
              @updateAmount="updateAmount"
              @disableNext="disableNext"
            />
            <div
              v-else
              data-cy="card-rate-zero-state"
              :class="$style['grey-container']"
            >
              <p>
                {{
                  $t(
                    'account.pages.pageVendors.bnplPaymentSchedule.enterAnAmount',
                    { type: handleType.toLowerCase() },
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="c-form__controls" :class="$style.form__controls">
          <DSButton
            label="Go back"
            class="p-button-link"
            :class="$style['back-button']"
            @click="$emit('prev-tab')"
          />
          <DSButton
            type="submit"
            :disabled="disabled"
            :class="$style['form__submit-button']"
            >{{ $t(`account.pages.pageVendors.nextStep`) }}</DSButton
          >
        </div>
      </VeeForm>

      <div :class="$style['bnpl-subject-to-approval']">
        <sup>*</sup>{{ footnote }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CardsRadio from '@/components/cards/CardsRadio';
import {
  CLEARPAY_SEGMENT_EVENTS,
  PAY_OUT_CURRENCY_OPTIONS,
  DEFAULT_CURRENCY,
} from '@/data/payments';
import { dollarsToCents, centsToDollars } from '@/utils/currency';
import analytics from '@/utils/analytics';
import { Form as VeeForm } from 'vee-validate';
import { object, string, number } from 'yup';
import InputNumberGroup from '@clearbanc/clear-components/inputnumbergroup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import DSButton from '@clearbanc/clear-components/button';
import { SEGMENT_EVENTS } from '../../data/segment-events';

const DEFAULT_REPAYMENT_TERMS = 4;
const INACTIVE_REPAYMENT_TERMS = 1;
const MAX_RECEIPT_FLOW = 10000000; // $10,000,000.00
const MAX_CENTS_RECEIPT_FLOW = MAX_RECEIPT_FLOW * 100;
const MIN_BILL_AMOUNT = 250;
const FORMATTED_MAX_RECEIPT = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
}).format(MAX_RECEIPT_FLOW);

export default {
  components: {
    CardsRadio,
    VeeForm,
    InputNumberGroup,
    InputTextGroup,
    DropdownGroup,
    DSButton,
  },
  props: {
    amount: {
      type: String,
      required: true,
    },
    dueDate: {
      type: String,
    },
    paymentMethod: {
      type: String,
      required: true,
    },
    selectedTerm: {
      type: [Number, String],
      required: true,
    },
    payloadCurrency: {
      type: String,
      default: '',
    },
    isReceipt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moneyMask: {
        code: PAY_OUT_CURRENCY_OPTIONS.USD.code,
        thousands: ',',
        decimal: '.',
        prefix: PAY_OUT_CURRENCY_OPTIONS.USD.symbol_native,
        precision: 2,
        masked: false,
      },
      isLoading: true,
      hasCurrencyChange: false,
      userTriggeredReceiptAmountError: false,
      termChosen:
        this.amount && this.amount !== '0.00'
          ? `${this.selectedTerm}`
          : `${DEFAULT_REPAYMENT_TERMS}`,
      disabled: false,
      // Note: receipt schema may be overwritten in beforeMount
      receiptSchema: object({
        amount: number()
          .nullable()
          .max(
            MAX_RECEIPT_FLOW,
            this.$t(
              'account.pages.pageVendors.bnplPaymentSchedule.amountError',
              {
                amount: FORMATTED_MAX_RECEIPT,
              },
            ),
          )
          .min(MIN_BILL_AMOUNT, `Amount cannot be less than ${MIN_BILL_AMOUNT}`)
          .required(this.$t('common.required')),
        payOutCurrency: string().required(this.$t('common.required')),
      }),
      // Note: invoice schema may be overwritten in beforeMount
      invoiceSchema: object({
        amount: number().nullable().required(this.$t('common.required')),
        payOutCurrency: string().required(this.$t('common.required')),
      }),
      timeoutReference: null,
    };
  },
  beforeMount() {
    const businessCapacity = this.businessCapacity.remainingCapacityCents;
    const maxCents =
      businessCapacity || this.remainingSelfReportedCapacityCents;
    if (maxCents > 0) {
      const convertedCentsToFloat = maxCents / 100;
      const formatMoney = this.$filters.currency(maxCents);
      if (maxCents < MAX_CENTS_RECEIPT_FLOW) {
        // should change the limit of RECEIPT amount field and update the error message
        this.receiptSchema = object({
          amount: number()
            .nullable()
            .max(
              convertedCentsToFloat,
              this.$t(
                'account.pages.pageVendors.bnplPaymentSchedule.capacityError',
                { type: this.handleType, money: formatMoney },
              ),
            )
            .min(
              MIN_BILL_AMOUNT,
              `Amount cannot be less than ${MIN_BILL_AMOUNT}`,
            )
            .required(this.$t('common.required')),
          payOutCurrency: string().required(this.$t('common.required')),
        });
      }
      // should change the limit of INVOICE amount field and update the error message
      this.invoiceSchema = object({
        amount: number()
          .nullable()
          .max(
            convertedCentsToFloat,
            this.$t(
              'account.pages.pageVendors.bnplPaymentSchedule.capacityError',
              { type: this.handleType, money: formatMoney },
            ),
          )
          .min(MIN_BILL_AMOUNT, `Amount cannot be less than ${MIN_BILL_AMOUNT}`)
          .required(this.$t('common.required')),
        payOutCurrency: string().required(this.$t('common.required')),
      });
    }
  },
  computed: {
    ...mapGetters([
      'repaymentSchedule',
      'business',
      'businessId',
      'businessCapacity',
      'isReceiptFlow',
      'remainingSelfReportedCapacityCents',
    ]),
    handleType() {
      return this.isReceiptFlow
        ? this.$t('account.pages.pageVendors.invoiceOrReceipt.receipt')
        : this.$t('account.pages.pageVendors.invoiceOrReceipt.invoice');
    },
    footnote() {
      return `
        ${this.$t('paymentsPage.extensionPlanFootnote')}
        ${
          this.hasCurrencyChange
            ? this.$t('paymentsPage.exchangeRateLegal')
            : ''
        }
      `;
    },
    amountCents() {
      return dollarsToCents(this.amount, this.selectedCurrency);
    },
    billAmountCents() {
      return this.selectedCurrency !== DEFAULT_CURRENCY &&
        this.repaymentSchedule.exchangeRateData
        ? this.repaymentSchedule.exchangeRateData.convertedAmountCents
        : dollarsToCents(this.amount, this.selectedCurrency);
    },
    conversionRate() {
      if (
        this.selectedCurrency !== DEFAULT_CURRENCY &&
        this.repaymentSchedule.exchangeRateData
      ) {
        return this.repaymentSchedule.exchangeRateData.conversionRate;
      }
      return null;
    },
    selectedTermRecord() {
      return this.repaymentSchedule?.repaymentSchedulesByMonth[
        this.selectedTerm
      ];
    },
    selectedTermSchedule() {
      return this.selectedTermRecord.schedule;
    },
    selectedTermRate() {
      return this.selectedTermRecord.feeRate;
    },
    selectedTermFeeCents() {
      return this.selectedTermRecord.feeAmountCents;
    },
    selectedTermTotalCents() {
      return this.selectedTermRecord.totalAmountCents;
    },
    selectedTermFeeVersionId() {
      return this.repaymentSchedule?.feeVersionId;
    },
    paymentPerTerm() {
      return centsToDollars(this.selectedTermTotalCents / this.selectedTerm);
    },
    weeklyPayment() {
      return centsToDollars(this.selectedTermRecord.paymentPerTermCents);
    },
    numWeeklyPayments() {
      return this.selectedTermRecord.totalNumPayments;
    },
    selectedCurrency() {
      return this.payloadCurrency ? this.payloadCurrency : this.moneyMask.code;
    },
    exchangeRate() {
      return this.conversionRate
        ? `${PAY_OUT_CURRENCY_OPTIONS[this.selectedCurrency].symbol_native}1 ${
            this.selectedCurrency
          } = $${Number(this.conversionRate).toFixed(4)} ${DEFAULT_CURRENCY}`
        : false;
    },
    currencyOptions() {
      const keys = Object.keys(PAY_OUT_CURRENCY_OPTIONS);
      return keys.map((value) => ({
        name: () => value,
        value,
      }));
    },
  },
  watch: {
    amount(newAmount) {
      if (
        newAmount !== '0.00' &&
        this.selectedTerm === INACTIVE_REPAYMENT_TERMS
      ) {
        this.termChosen = `${DEFAULT_REPAYMENT_TERMS}`;
        this.$emit('get-repayment-term', DEFAULT_REPAYMENT_TERMS);
      }

      if (newAmount === '0.00') {
        this.termChosen = `${INACTIVE_REPAYMENT_TERMS}`;
        this.$emit('get-repayment-term', INACTIVE_REPAYMENT_TERMS);
      }

      this.getFeesSchedule(this.selectedCurrency);
    },
  },
  unmounted() {
    clearInterval(this.timeout);
  },
  async mounted() {
    await this.getFeesSchedule(this.selectedCurrency);
    this.isLoading = false;
  },
  methods: {
    getStepValue(val) {
      this.termChosen = val;
      this.$emit('get-repayment-term', val);
    },
    updateAndContinue() {
      if (!this.selectedTermRecord) {
        return;
      }
      this.$emit('repayment-details', {
        termRate: this.selectedTermRate,
        selectedTerm: this.selectedTerm,
        numWeeklyPayments: this.numWeeklyPayments,
        paymentPerTerm: this.weeklyPayment,
        feeCents: this.selectedTermFeeCents,
        feeVersionId: this.selectedTermFeeVersionId,
        repaymentSchedule: this.selectedTermSchedule,
      });
      analytics.track(SEGMENT_EVENTS.if_clicked_bnpl_payment_schedule_next, {
        businessId: this.businessId,
        businessName: this.business.name,
        selectedTerm: this.numWeeklyPayments,
        billAmount: Number(this.amount) || null,
      });
      this.$emit('next-tab');
    },
    updateAmountDebounced(event) {
      if (this.timeoutReference) {
        clearTimeout(this.timeoutReference);
        this.timeoutReference = null;
      }

      this.timeoutReference = setTimeout(() => {
        this.updateAmount(event);
        if (event.value < MIN_BILL_AMOUNT) {
          analytics.track('invoice_amount_error_displayed');
        }
      }, 1000);
    },
    updateAmount({ value }) {
      if (!value) return '';
      let newValue = value;
      if (Number.isInteger(value)) {
        newValue = value.toFixed(2);
      }
      this.checkTriggeredReceiptAmountError(value);
      return this.$emit('amount', String(newValue));
    },
    checkTriggeredReceiptAmountError(value) {
      if (
        value > MAX_RECEIPT_FLOW &&
        this.isReceiptFlow &&
        !this.userTriggeredReceiptAmountError
      ) {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.AMOUNT_ERROR_MORE_THAN_100K, {
          type: 'receipt',
          amount: value,
          error: this.$t(
            'account.pages.pageVendors.bnplPaymentSchedule.capacityError',
            { money: this.$filters.currency(value * 100) },
          ),
        });
        this.userTriggeredReceiptAmountError = true;
      }
    },
    disableNext(val) {
      this.disabled = val;
    },
    currencyChange({ value }) {
      this.getFeesSchedule(value);
    },
    async getFeesSchedule(fromCurrency) {
      await this.$store.dispatchApiAction('GET_FEES_SCHEDULE', {
        dueDate: this.isReceipt ? `${new Date()}` : this.dueDate,
        amountCents: this.amountCents,
        fromCurrency,
        toCurrency: DEFAULT_CURRENCY,
      });
      this.moneyMask.prefix =
        PAY_OUT_CURRENCY_OPTIONS[fromCurrency].symbol_native;
      this.moneyMask.code = PAY_OUT_CURRENCY_OPTIONS[fromCurrency].code;
      this.hasCurrencyChange = fromCurrency !== DEFAULT_CURRENCY;
      this.$emit('pay-out-currency', fromCurrency);
    },
  },
};
</script>

<style lang="less" module>
.form {
  text-align: left;
  width: 100%;

  .form__controls {
    text-align: center;
    justify-content: center;
  }
}

.form__submit-button {
  min-width: 8rem;
  justify-content: center;
}

.grey-container {
  min-height: 390px;
  background-color: #f8f8fa;
  margin: 32px 14%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;

  p {
    font-family: @gerstner-font;
    color: @color-grey-400;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    margin: 16px;
    line-height: 24px;
    text-align: center;
  }

  @media only screen and (max-width: 800px) {
    margin: 16px;
  }
}

.amount-wrapper {
  margin: 0 auto;
  max-width: 378px;
  text-align: left;
  display: flex;
}

.bnpl-subject-to-approval {
  font-size: 12px;
  font-family: @gerstner-font;
  line-height: 16px;
  font-weight: 300;
  color: #767676;
  margin-top: 30px;
  margin-bottom: 0;
  max-width: 690px;
}

.tooltip {
  color: @color-black;
  padding: 4px;
  cursor: help;
}
</style>
