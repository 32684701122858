<template>
  <div :class="$style.container">
    <Modal
      ref="modal"
      :visible="showModal"
      large
      :title="$t('components.tileGroupOwnersList.areYouSure')"
      show-close-button
      data-cy="delete-owner-modal"
      @close="showModal = false"
    >
      <div :class="$style['modal-text']">{{ modalMsg }}</div>

      <div :class="$style['btn-box']">
        <OnboardingButton
          :text="$t('common.buttons.goBack')"
          :class="$style['modal-btn']"
          :theme="$t('components.tileGroupOwnersList.white')"
          @click="showModal = false"
          @keyup.enter="showModal = false"
        />
        <OnboardingButton
          :text="$t('common.buttons.delete')"
          :class="$style['modal-btn']"
          data-cy="delete-owner-button"
          @click="deleteOwner"
          @keyup.enter="deleteOwner"
        />
      </div>
    </Modal>
    <Box
      v-for="(owner, index) in allOwners"
      :key="owner.id"
      :data-cy="`owner-box-${index}`"
      :class="$style.box"
    >
      <div :class="$style.row">
        <div data-cy="full-name" :class="$style.title">
          {{ owner.firstName }} {{ owner.lastName }}
        </div>
        <div :class="$style['div-actions']">
          <div
            :class="$style['icon-box']"
            data-cy="edit-button"
            @click="handleEditClick(owner)"
            @keyup.enter="handleEditClick(owner)"
          >
            <svg
              :class="$style.icon"
              tabindex="0"
              v-html="require('@/assets/icons/pencil-black-border.svg?raw')"
            ></svg>
          </div>
          <div
            v-if="!owner.isPrimary"
            :class="$style['icon-box']"
            data-cy="delete-owner-link"
            @click="handleTrashClick(owner)"
            @keyup.enter="handleTrashClick(owner)"
          >
            <svg
              :class="$style.icon"
              tabindex="0"
              v-html="require('@/assets/icons/trash-can.svg?raw')"
            ></svg>
          </div>
        </div>
      </div>

      <div data-cy="title-and-email" :class="$style['position-email-box']">
        {{ owner.jobTitle }}{{ owner.email ? `, ${owner.email}` : '' }}
      </div>

      <div v-if="this.hasUserIdUpload(owner)" :class="$style.row">
        <div
          :class="$style['icon-box']"
          data-cy="view-id-link"
          @click="handleViewIdClick(owner)"
          @keyup.enter="handleViewIdClick(owner)"
        >
          <svg
            :class="$style.icon"
            tabindex="0"
            v-html="require('@/assets/icons/id.svg?raw')"
          ></svg>
          <div :class="$style['icon-box-text']">
            {{ $t('components.tileGroupOwnersList.viewID') }}
          </div>
        </div>
      </div>

      <div :class="$style.row">
        <div :class="$style.subtitle">{{ $t('common.phoneNumber') }}</div>
        <div :class="$style.subtitle">
          {{ $t('components.tileGroupOwnersList.ssnSin') }}
        </div>
      </div>

      <div :class="$style.row">
        <div data-cy="phone">{{ owner.phoneNumber }}</div>
        <div data-cy="ssn">{{ getHiddenSSN(owner.personalTaxId) }}</div>
      </div>

      <div :class="$style.row">
        <div :class="$style.subtitle">
          {{ $t('common.address.homeAddress') }}
        </div>
      </div>

      <div :class="$style.row">
        <div data-cy="address" :class="$style['address-box']">
          {{ owner.address.line1
          }}{{ owner.address.city ? `, ${owner.address.city}` : ''
          }}{{ owner.address.state ? `, ${owner.address.state}` : '' }}
          {{ owner.address.postalCode }}
          {{ getCountryName(owner.address.country) }}
        </div>
      </div>

      <div :class="$style.row">
        <div :class="$style.subtitle">
          {{ $t('components.tileGroupOwnersList.youAre') }}
        </div>
        <div :class="$style.subtitle">
          {{ $t('components.tileGroupOwnersList.citizenship') }}
        </div>
      </div>

      <div :class="$style.row">
        <div :class="$style['text-left']">
          <template v-if="owner?.jobRoles?.length">
            <div
              v-for="(role, roleIndex) in owner.jobRoles"
              :key="roleIndex"
              :data-cy="`owner-roles-${roleIndex}`"
            >
              {{ getRoleDescription(role) }}
            </div>
          </template>
          <template v-else>-</template>
        </div>
        <div data-cy="country" :class="$style['text-right']">
          {{ getCountryName(owner.citizenship) }}
        </div>
      </div>
    </Box>

    <OnboardingButton
      :text="$t('components.tileGroupOwnersList.addAnotherOwnerOrDirector')"
      :class="$style['add-owner-button']"
      theme="white"
      data-cy="add-another-owner-or-director-button"
      @click="handleAddOwnerClick"
      @keyup.enter="handleAddOwnerClick"
    />
    <slot name="cta" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { downloadFileMixin } from '@/utils/vue-mixins';
import { COUNTRY_CODE_WITH_NAMES } from '@/data/country-code-with-names';
import analytics from '@/utils/analytics';
import {
  OWNER_ROLES,
  ENTITY_TYPE,
  ID_TYPE,
} from '@clearbanc/data-common-types';
import Box from '@/components/Box';
import OnboardingButton from '@/components/buttons/ButtonOnboarding2';
import Modal from '@/components/modals/ModalOnboarding2';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

export default {
  components: {
    Box,
    OnboardingButton,
    Modal,
  },
  mixins: [downloadFileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
  },
  data() {
    return {
      showModal: false,
      ownerIdToDelete: null,
    };
  },
  mounted() {
    if (!this.hasSignatory) {
      this.$emit('showError', this.signatoryErrorMsg);
      analytics.track('fe_error_banner_displayed', {
        ...this.eventTrackingFields,
        errorMsg: this.signatoryErrorMsg,
      });
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'businessOwners',
      'uploads',
      'allOwners',
      'uploadsMatching',
      'isDiligenceServiceReadDataEnabled',
      'hasSignatory',
    ]),
    ...mapRequestStatuses({
      deleteUserRequest: 'DELETE_NON_PRIMARY_USER',
    }),
    currentRoute() {
      return this.$route.name;
    },
    signatoryErrorMsg() {
      return this.$t('components.tileGroupOwnersList.signatoryErrorMsg');
    },
    modalMsg() {
      return this.$t(
        'components.tileGroupOwnersList.modalMsg.confirmDeleteUser',
      );
    },
  },
  methods: {
    async validateForm() {
      if (this.hasSignatory) {
        this.$emit('validationSuccess');
      }
    },
    getRoleDescription(role) {
      switch (role) {
        case OWNER_ROLES.OWNER:
          return this.$t('common.owner');
        case OWNER_ROLES.DIRECTOR:
          return this.$t('common.directorBoardMember');
        case OWNER_ROLES.SIGNATORY:
          return this.$t('common.authorizedSignatory');
        default:
          return '';
      }
    },
    async handleEditClick(owner) {
      analytics.track('fe_owner_confirmation_edit_click', {
        ...this.eventTrackingFields,
        email: owner.email,
      });
      await this.$store.dispatch('SET_SELECTED_OWNER', owner.id);
      this.$emit('editOwner', owner);
    },
    async handleAddOwnerClick() {
      analytics.track('fe_owner_summary_tile', this.eventTrackingFields);
      const id = 'new';
      await this.$store.dispatch('SET_SELECTED_OWNER', id);
      this.$emit('addOwner', id);
    },
    async deleteOwner() {
      const id = this.ownerIdToDelete;
      if (id !== this.user.id) {
        const ownerToDelete = this.businessOwners.find(
          (owner) => owner.id === id,
        );
        await this.$store.dispatchApiAction(
          'DELETE_NON_PRIMARY_USER',
          ownerToDelete,
        );
      }
      this.showModal = false;
    },
    handleTrashClick(owner) {
      analytics.track('fe_delete_owner_details_click', {
        ...this.eventTrackingFields,
        email: owner.email,
      });
      this.ownerIdToDelete = owner.id;
      this.showModal = true;
    },
    handleViewIdClick(owner) {
      analytics.track('fe_owner_confirmation_view_id_click', {
        ...this.eventTrackingFields,
        email: owner.email,
      });
      this.downloadFiles(owner.id);
    },
    async downloadFiles(userId) {
      const ids = [];
      this.uploads.forEach((file) => {
        if (file.metaId === userId.toString()) {
          ids.push(file.id);
        }
      });
      ids.forEach(async (id) => {
        await this.downloadFile(id);
      });
    },
    getHiddenSSN(ssn) {
      return ssn
        ? '*'.repeat(ssn.length - 1).concat(ssn.charAt(ssn.length - 1))
        : '';
    },
    getCountryName(countryCode) {
      if (!countryCode) return '';
      return COUNTRY_CODE_WITH_NAMES[countryCode]();
    },
    hasUserIdUpload(owner) {
      const ownerFilters = this.getDisplayFilters(owner);
      return !!this.$store.getters.uploadsMatching(ownerFilters).length;
    },
    getDisplayFilters(owner) {
      return this.isDiligenceServiceReadDataEnabled
        ? {
            externalId: `${owner.id}`,
            externalIdType: owner.isPrimary
              ? ENTITY_TYPE.USER
              : ENTITY_TYPE.OWNER,
            documentType: [ID_TYPE.ID, ID_TYPE.PASSPORT],
          }
        : {
            metaId: `${owner.id}`,
            entity: owner.isPrimary ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
            type: 'passport_or_license',
          };
    },
  },
};
</script>

<style lang="less" module>
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  min-width: 330px;
  max-width: 450px;

  .modal-text {
    margin: 30px 20px 0;
    color: @dark-black;
    @media only screen and (max-width: 500px) {
      margin: 30px 5px 0;
      text-align: left;
    }
  }

  .btn-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
    @media only screen and (max-width: 500px) {
      position: absolute;
      bottom: 0;
    }
  }

  .modal-btn {
    margin: 0 10px 40px;
    cursor: pointer;
    min-width: 200px;
    @media only screen and (max-width: 500px) {
      min-width: 160px;
      margin: 0 auto 10px;
    }
  }

  .box {
    width: 100%;
    margin: 10px auto;
    padding: 30px 22px;

    @media only screen and (max-width: 500px) {
      padding: 30px 22px;
      margin: 10px auto;
    }
  }

  .div-actions {
    display: flex;
    div {
      margin-left: 20px;
    }
  }

  .icon-box {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      width: 22px;
      height: 22px;
    }

    .icon-box-text {
      text-decoration: underline;
      display: inline-block;
      margin: 4px 0 0 6px;
      color: @lighter-link-blue;
    }
  }

  .title {
    .row;

    font-weight: 500;
    font-size: 21px;
    flex-direction: start;
  }

  .subtitle {
    color: @medium-gray;
    margin-top: 18px;
    margin-bottom: 5px;
  }

  .row {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .address-box {
    .text-left;

    margin-right: 5px;
  }

  .position-email-box {
    .row;
    .subtitle;

    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
  }

  .add-owner-button {
    border: 1px solid @color-black;
    width: 100%;
    margin: 15px auto 35px;

    [class^='button-text'] {
      line-height: 20px;
    }
  }
}
</style>
