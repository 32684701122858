<script setup>
import { computed, readonly, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';

import { OWNER_ROLES, REGEXES } from '@clearbanc/data-common-types';
import { JobTitlesByCorpCountry } from '@/data/owner';
import { getParsedMessage } from '@/data/error-messages';
import analytics from '@/utils/analytics';
import { objectDiff } from '@/utils/object-comparison';
import { EXTERNAL_FORM_TYPES } from '@/data/user-invite';

import { BackButton } from '@/components';
import { useToast } from '@/composables/useToast';
import CheckboxGroup from '@clearbanc/clear-components/checkboxgroup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';

// -----
// Setup
// -----

const store = useStore();
const toast = useToast('bottom-left');
const emits = defineEmits(['onBack', 'onSuccess', 'onError']);

const props = defineProps({
  owner: {
    type: Object,
  },
});

// ---------
// Constants
// ---------

const requestStatuses = readonly({
  createNonPrimaryUser: computed(() =>
    store.getters.requestStatus('CREATE_NON_PRIMARY_USER'),
  ),
  createUserInvite: computed(() =>
    store.getters.requestStatus('CREATE_USER_INVITE'),
  ),
  updateNonPrimaryUser: computed(() =>
    store.getters.requestStatus('UPDATE_NON_PRIMARY_USER'),
  ),
});

const jobTitles = computed(() =>
  JobTitlesByCorpCountry(store.getters.business.corpCountry),
);

const pendingUserInvite = computed(() =>
  props.owner
    ? store.getters.userInvites.find(
        (invite) =>
          invite.invitedUserId === Number(props.owner.id) &&
          !invite.fulfilledAt,
      )
    : undefined,
);

const isEditMode = readonly(Boolean(props.owner));

const handleError = (error) => {
  emits('onError', getParsedMessage(error, true));
};

const validationSchema = object({
  firstName: string()
    .required('Required')
    .matches(
      REGEXES.NAME,
      'Only letters, valid punctuation, and no consecutive or trailing spaces',
    ),
  lastName: string()
    .required('Required')
    .matches(
      REGEXES.NAME,
      'Only letters, valid punctuation, and no consecutive or trailing spaces',
    ),
  email: string().nullable().required('Required').email(),
  jobTitle: object().nullable().required('Required'),
});

// ---------
// Variables
// ---------

const { values, setValues, handleSubmit, isSubmitting, errors } = useForm({
  validationSchema,
});

const isFormStateValid = computed(() => {
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return true;
  } catch {
    return false;
  }
});

const hasErrors = computed(
  () => !isFormStateValid.value || !!Object.keys(errors.value).length,
);

// ----
// Form
// ----

const onSubmit = handleSubmit(async () => {
  const payload = {
    ...values,
    jobTitle: values.jobTitle?.value,
    jobRoles: values.jobRoles?.filter((role) => !!role).flat(),
  };
  if (isEditMode) {
    const { changedProps } = objectDiff(payload, props.owner);
    if (!Object.keys(changedProps).length) {
      emits('onSuccess');
      return;
    }
    await store.dispatchApiAction('UPDATE_NON_PRIMARY_USER', {
      ...changedProps,
      id: props.owner.id,
    });
    if (requestStatuses.updateNonPrimaryUser.isError) {
      handleError(requestStatuses.updateNonPrimaryUser.error);
    } else {
      toast.success(`Changes saved.`);
      emits('onSuccess');
    }
    return;
  }

  const newOwner = await store.dispatchApiAction(
    'CREATE_NON_PRIMARY_USER',
    payload,
  );

  if (requestStatuses.createNonPrimaryUser.isError) {
    handleError(requestStatuses.createNonPrimaryUser.error);
    return;
  }

  await store.dispatchApiAction('CREATE_USER_INVITE', {
    id: newOwner.id,
  });

  if (requestStatuses.createUserInvite.isError) {
    handleError(requestStatuses.createUserInvite.error);
    return;
  }

  analytics.track('if_identity_verification_email_btn_requested', {
    secondary_user_email: values.email,
    secondary_user_userid: newOwner.id,
    business_id: store.getters.business.id,
  });

  const userName = `${newOwner.firstName} ${newOwner.lastName}`;
  toast.success(`ID verification email sent to ${userName}.`);

  emits('onSuccess', newOwner);
});

const onEditRequest = async () => {
  await store.dispatchApiAction('CREATE_USER_INVITE', {
    id: props.owner.id,
    type: EXTERNAL_FORM_TYPES.EDIT,
  });

  if (requestStatuses.createUserInvite.isError) {
    handleError(requestStatuses.createUserInvite.error);
    return;
  }

  const userName = `${props.owner.firstName} ${props.owner.lastName}`;
  toast.success(`Email sent to ${userName}.`);
  emits('onSuccess');
};

// ---------
// Lifecycle
// ---------

onMounted(() => {
  if (props.owner) {
    const director = props.owner.jobRoles?.includes(OWNER_ROLES.DIRECTOR)
      ? [OWNER_ROLES.DIRECTOR]
      : null;
    const owner = props.owner.jobRoles?.includes(OWNER_ROLES.OWNER)
      ? [OWNER_ROLES.OWNER]
      : null;
    const signatory = props.owner.jobRoles?.includes(OWNER_ROLES.SIGNATORY)
      ? [OWNER_ROLES.SIGNATORY]
      : null;
    setValues({
      firstName: props.owner.firstName,
      lastName: props.owner.lastName,
      email: props.owner.email,
      jobTitle: jobTitles.value?.find(
        (item) => item.value === props.owner.jobTitle,
      ),
      jobRoles: [director, owner, signatory],
    });
  }
});
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <BackButton :class="$style['back-button']" @click="emits('onBack')" />
      <h3 :class="$style.title">New Business Owner/Director</h3>
    </div>
    <form
      ref="form"
      class="c-form"
      :class="$style.form"
      :style="{ pointerEvents: isSubmitting ? 'none' : 'all' }"
      @submit="onSubmit"
    >
      <div class="c-group">
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-12">
            <InputTextGroup
              :disabled="isEditMode"
              name="firstName"
              label="First Name"
            />
          </div>
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-12">
            <InputTextGroup
              :disabled="isEditMode"
              name="lastName"
              label="Last Name"
            />
          </div>
          <div class="col-span-12">
            <InputTextGroup
              :disabled="isEditMode"
              name="email"
              label="Email Address"
            />
          </div>
          <div class="col-span-12">
            <DropdownGroup
              name="jobTitle"
              :label="$t('common.jobTitle')"
              option-label="label"
              :options="jobTitles"
              :placeholder="$t('common.selectOption')"
              append-to="self"
            />
          </div>
          <div class="col-span-12">
            <CheckboxGroup
              name="jobRoles[0]"
              :options="[
                {
                  value: OWNER_ROLES.DIRECTOR,
                  label: 'This individual is a Director / Board member',
                },
              ]"
            />
            <CheckboxGroup
              name="jobRoles[1]"
              :options="[
                {
                  value: OWNER_ROLES.OWNER,
                  label: 'This individual has 25%+ ownership',
                },
              ]"
            />
          </div>
        </div>
        <Message
          v-if="isEditMode && !pendingUserInvite"
          severity="info"
          :closable="false"
          :class="[$style.message, $style['message--small']]"
        >
          <p>
            To edit any other information, please click 'Request edit.' An email
            will be sent to this individual with a request to review and
            complete their identity information.
          </p>
        </Message>
        <div v-if="!isEditMode" :class="$style.footer">
          An email will be sent to this email address and this individual will
          be required to verify their identity.
        </div>
        <div :class="$style.actions">
          <DSButton
            :label="
              isEditMode
                ? 'Save'
                : 'Save and send email link to ID verification'
            "
            :class="$style.actions__button"
            :loading="isSubmitting"
            :disabled="hasErrors"
            type="submit"
          />
          <DSButton
            v-if="isEditMode && !pendingUserInvite"
            label="Request edit"
            class="p-button-outlined"
            :class="$style.actions__button"
            @click="onEditRequest"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped module lang="less">
.container {
  padding: 20px;
  margin-top: 40px;
  border: 1px solid #e8e8ea;
  box-shadow: 0 2px 8px 0 #0000000f;
  background: #fff;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.back-button {
  margin-right: 10px;
}

.title {
  color: var(--neutral-black, #000);
  font-family: @gerstner-font;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.footer {
  margin-top: 40px;
}

.actions {
  margin-top: 40px;
}

.actions__button {
  width: 100%;
}

.actions__button + .actions__button {
  margin-top: 16px;
}

@media (min-width: 800px) {
  .container {
    padding: 40px;
  }
}
</style>
