<template>
  <button
    :class="[
      this.$style['application-step'],
      isDisabled && this.$style['application-step--disabled'],
    ]"
    :data-cy="kebabCase(title)"
    @click="handleClick"
  >
    <div
      v-if="!isError && !isWarning && !isComplete && stepCount"
      :class="$style['step-count']"
    >
      {{ stepCount }}
    </div>
    <svg
      v-else-if="!!statusIconPath"
      :class="$style['status-icon']"
      data-cy="warning-icon"
      v-html="statusIconPath"
    ></svg>
    <div :class="$style['wrapper']">
      <p :class="$style.title">{{ title }}</p>
      <Tag
        v-if="actionRequired"
        :class="$style.actionTag"
        severity="warning"
        value="Action Required"
      ></Tag>
    </div>
    <div v-if="isLocked" :class="$style.icon" v-html="lockedIcon" />
    <svg v-else :class="$style.chevron" v-html="chevronPath"></svg>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
import Chevron from '@/assets/icons/next-step.svg?inline';
import Check from '@/assets/icons/check.svg?inline';
import Error from '@/assets/icons/error.svg?inline';
import Warning from '@/assets/icons/warning-info.svg?inline';
import kebabCase from '@/utils/kebabcase';
import analytics from '@/utils/analytics';
import {
  sendSegmentTrackEvent,
  formatStepLabelForSegment,
} from '@/utils/outbound-onboarding-v2';
import Tag from '@clearbanc/clear-components/tag';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

const STEP_STATES = {
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  ERROR: 'error',
  WARNING: 'warning',
};

export default {
  components: { Tag },
  props: {
    title: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: true,
      validator: (state) => Object.values(STEP_STATES).includes(state),
    },
    destination: {
      type: String,
      required: true,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    stepCount: {
      type: Number,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    actionRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['user']),
    isComplete() {
      return this.state === STEP_STATES.COMPLETE;
    },
    isError() {
      return this.state === STEP_STATES.ERROR;
    },
    isWarning() {
      return this.state === STEP_STATES.WARNING;
    },
    lockedIcon() {
      return require('@/assets/icons/lock-outbound-v2.svg?raw');
    },
    chevronPath() {
      return Chevron;
    },
    statusIconPath() {
      if (this.isError) {
        return Error;
      }
      if (this.isWarning) {
        return Warning;
      }
      if (this.isComplete) {
        return Check;
      }
      return '';
    },
  },
  methods: {
    kebabCase,
    handleClick() {
      if (this.isDisabled) return;

      sendSegmentTrackEvent(this.$route.name, 'btn_click', {
        element: 'module-btn',
        module: formatStepLabelForSegment(this.title, false),
        blocked: this.isLocked,
      });

      if (this.destination === PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS) {
        analytics.track('fe_business_owner_profiles_clicked');
      }

      if (!this.isLocked) {
        this.$router.push({
          name: this.destination,
          params: { id: this.user.id },
        });
      } else {
        this.$emit('stepLocked');
      }
    },
  },
};
</script>

<style lang="less" module>
.application-step {
  all: unset;
  box-sizing: border-box;
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  border: 1px solid #ceced0;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  gap: 1rem;
  padding: 1rem 1.5rem;

  .step-count {
    display: flex;
    color: @color-lightgray-300;
    border: 1px solid @color-lightgray-300;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    font-size: 12px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-top: 5px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 6px;

    .title {
      font-family: @gerstner-font;
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #000000;
      text-align: left;
      margin: 0;
    }

    .actionTag {
      text-align: center;
    }
  }

  & + .application-step,
  & + [class^='p-button'] {
    margin-top: 1rem;
  }

  @media only screen and (min-width: 960px) {
    & + .application-step,
    & + [class^='p-button'] {
      margin: 1.5rem 0 0;
    }
  }

  .chevron {
    margin-left: 10px;
    width: 1rem;
    height: 1rem;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .status-icon {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin: 0 1rem 0 0;
  }
}

.application-step:focus {
  outline: none;
}

.application-step:hover {
  .chevron {
    transform: translateX(0.75rem);
  }
}

.application-step--disabled {
  cursor: default;
  background-color: #f8f8fa;

  .title {
    color: #ababad;
  }
}

.application-step--disabled:hover {
  .chevron {
    transform: unset;
  }
}

.icon {
  display: contents;

  svg {
    width: 16px;
    height: 16px;
    margin: 10px;
  }
}
</style>
