<template>
  <OnboardingModal
    ref="modal"
    data-cy="verification-modal"
    large
    visible
    :title="$t('components.modalIdentityVerification.identityVerification')"
    :show-close-button="false"
    :class="$style.modal"
  >
    <div :class="$style.container">
      <p>
        {{ $t('components.modalIdentityVerification.serviceAgreement') }}
      </p>
      <div :class="$style.subcontainer">
        <Checkbox
          id="identity-verification-checkbox"
          v-model="params.hasConsented"
          name="iv"
          :class="$style['fit-width']"
          required
          @input="handleVerificationInput"
        >
          {{ $t('common.buttons.agree') }}
        </Checkbox>
        <OnboardingButton
          :text="$t('common.buttons.continue')"
          :class="$style['button-width']"
          :disabled="loading"
          :loading="loading"
          @click="handleContinueButtonPressed"
        />
      </div>
    </div>
  </OnboardingModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateChildren } from '@/composables/validation';
import analytics from '@/utils/analytics';
import Checkbox from '@/components/Checkbox';
import OnboardingButton from '@/components/buttons/ButtonOnboarding2';
import OnboardingModal from '@/components/modals/ModalOnboarding2';

export default {
  components: { OnboardingButton, OnboardingModal, Checkbox },
  props: {
    eventTrackingFields: {
      type: Object,
      default: () => {},
    },
    eventNames: {
      type: Object,
      default() {
        return {
          toggleCheckbox: 'fe_identity_verification_check',
          continueClick: 'fe_onboarding_next_step_click',
        };
      },
    },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      params: {
        hasConsented: false,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'selectedOwner']),
  },
  methods: {
    async handleContinueButtonPressed() {
      const error = await this.hasError();
      if (!error) {
        this.loading = true;
        await this.$store.dispatchApiAction('UPDATE_USER', {
          agreedToKyc: !!this.params.hasConsented,
        });
        if (this.selectedOwner?.id === this.user.id) {
          this.$store.dispatch('SET_SELECTED_OWNER', this.selectedOwner.id);
        }
        this.trackNextStepClick();
        this.$emit('validationSuccess');
        this.loading = false;
      }
    },
    async handleVerificationInput() {
      analytics.track(this.eventNames.toggleCheckbox, {
        ...this.eventTrackingFields,
        check: this.hasConsented,
      });
    },
    trackNextStepClick() {
      analytics.track(this.eventNames.continueClick, {
        ...this.eventTrackingFields,
        skip: null,
      });
    },
  },
};
</script>

<style lang="less" module>
.modal {
  height: 100vh;
}
.subcontainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
}
.container {
  text-align: left;
  padding: 36px 36px 36px 36px;
}
.fit-width {
  width: 150px;
}
.button-width {
  width: 186px;
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 0;
  }
}
</style>
