<template>
  <component
    :is="tagType"
    class="button"
    :to="routerLinkTo"
    :href="href"
    :class="classes"
    :target="targetBlank ? '_blank' : '_top'"
    :type="buttonType"
    @click="$emit('click')"
  >
    <template v-if="loading">
      <icon name="spinner" /><span>{{ loadingText }}</span>
    </template>
    <template v-else>
      <icon v-if="icon" :name="icon" />
      <slot />
    </template>
  </component>
</template>

<script>
const BUTTON_SIZES = ['small', 'medium', 'large', 'xlarge'];
const BUTTON_THEMES = [
  'dark',
  'light',
  'white',
  'blue',
  'green',
  'red',
  'yellow',
  'green-dark',
  'transparent-light',
  'transparent-dark',
  'transparent-blue',
  'icon-button-dark',
];

export default {
  emits: ['click'],
  props: {
    size: {
      type: String,
      validator: (val) => BUTTON_SIZES.includes(val),
    },
    theme: {
      type: String,
      validator: (val) => BUTTON_THEMES.includes(val),
      default: 'blue',
    },
    href: String, // passes through to <a>
    to: [String, Object], // passes through to <router-link>
    toNamedRoute: String,
    disabled: Boolean,
    loading: Boolean,
    targetBlank: Boolean,
    loadingText: {
      type: String,
      default: 'Loading...',
    },
    icon: String,
    inline: Boolean,
  },
  computed: {
    tagType() {
      if (this.href) return 'a';
      if (this.to || this.toNamedRoute) return 'router-link';
      return 'button';
    },
    buttonType() {
      // otherwise <button> defaults to type=submit and makes forms submit
      return this.tagType === 'button' ? 'button' : undefined;
    },
    classes() {
      return {
        'is-disabled': this.disabled,
        'is-loading': this.loading,
        ...(this.size && { [`button--${this.size}`]: true }),
        ...(this.theme && { [this.theme]: true }),
        ...(this.inline && { 'button--inline-important': true }),
      };
    },
    routerLinkTo() {
      return this.toNamedRoute ? { name: this.toNamedRoute } : this.to;
    },
  },
};
</script>

<style lang="less">
.button {
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  line-height: 1.15rem;
  text-align: center;
  text-shadow: none;
  transition: 0.25s all;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  user-select: none;
  margin-right: 3px;
  margin-bottom: 1px;
  padding: 8px 20px;

  .icon {
    vertical-align: middle;
    margin-right: 10px;
    margin-left: -8px;
    margin-top: -2px;
    width: 20px;
    height: 20px;
  }

  &:focus {
    outline: none;
  }

  // Size options
  &.button--small {
    padding: 4px 10px;

    .icon {
      margin-right: 5px;
      margin-left: -3px;
      width: 16px;
      height: 16px;
    }
  }

  &.button--large {
    padding: 15px 40px;
  }

  &.button--xlarge {
    padding: 10px 60px;
    max-width: 100%;
    font-size: 16px;
  }

  &.full {
    width: 100%;
  }

  &.is-disabled,
  &.is-loading {
    opacity: 0.3;
    pointer-events: none;
  }

  &.is-loading {
    opacity: 0.5;
    border-color: @dark-gray;
    color: @dark-gray;
    background: rgba(0, 0, 0, 0);
  }

  &.float-right {
    float: right;
    margin-top: 10px;
  }

  // Color Theme options
  .create-theme(@color) {
    background-color: @color;
    color: contrast(@color);

    &:hover when (lightness(@color) > 50%) {
      background: darken(@color, 10%);
    }

    &:hover when (lightness(@color) < 50%) {
      background: lighten(@color, 10%);
    }
  }

  .create-transparent-theme(@color) {
    border-color: @color;
    color: @color;
    background: fade(@color, 0);

    &:hover {
      background: fade(@color, 60);
      color: contrast(@color);
      border-color: @color;
    }
  }

  .create-icon-button-theme(@color) {
    color: @color;

    &:hover {
      color: @cta-blue;
    }
  }

  &.blue {
    .create-theme(@cta-blue);
  }

  &.green {
    .create-theme(@green);
  }

  &.red {
    .create-theme(@error-red-bg);
  }

  &.yellow {
    .create-theme(@yellow);
  }

  &.dark {
    .create-theme(@navy);
  }

  &.light {
    .create-theme(@gray-blue);
  }

  &.white {
    .create-theme(#ffffff);
  }

  &.green-dark {
    .create-theme(@green-dark);
  }

  &.transparent-dark {
    .create-transparent-theme(@dark-gray);
  }

  &.transparent-light {
    .create-transparent-theme(#ffffff);
  }

  &.transparent-blue {
    .create-transparent-theme(@cta-blue);
  }

  &.icon-button-dark {
    .create-icon-button-theme(@dark-gray);
  }

  &.header {
    background-color: #019bc9;
    color: white !important;

    &:hover {
      background-color: #06abdc;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      text-shadow: none;
    }

    &:active {
      background-color: #008ab3;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
  }

  &.button--inline-important {
    display: inline !important;
    width: auto !important;
  }
}
</style>
