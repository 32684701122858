import {
  getWebInstrumentations,
  initializeFaro,
  faro,
} from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from '@opentelemetry/core';
import { B3Propagator, B3InjectEncoding } from '@opentelemetry/propagator-b3';

export function initFaroRuntime(
  collectorUrl,
  appName,
  environment,
  appVersion,
) {
  const cbIgnoreUrls = [
    'https://faro-collector-prod-us-central-0.grafana.net',
    'https://login.staging.clearbanc.com',
    'https://login.clearbanc.com',
  ];

  // WARNING: Be careful with the values here, do NOT add login.clearbanc.com
  // Also values must be identical for both prod and staging for parity.
  const traceCorsUrls = [
    new RegExp('https://api.staging.clearbanc.com/.*'),
    new RegExp('https://staging.clearbanc.com/.*'),
    new RegExp('https://api.clearbanc.com/.*'),
    new RegExp('https://my.clearbanc.com/.*'),
  ];

  if (!collectorUrl || !appName || environment === 'test') {
    // Do not initialize faro without these
    return null;
  }

  const runtime = initializeFaro({
    url: collectorUrl,
    trackWebVitalsAttribution: true,
    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation({
        instrumentationOptions: {
          ignoreUrls: cbIgnoreUrls,
          propagateTraceHeaderCorsUrls: traceCorsUrls,
        },
        resourceAttributes: {
          env: environment,
        },
        propagator: new CompositePropagator({
          propagators: [
            new W3CTraceContextPropagator(),
            new W3CBaggagePropagator(),
            new B3Propagator(),
            new B3Propagator({
              injectEncoding: B3InjectEncoding.MULTI_HEADER,
            }),
          ],
        }),
      }),
    ],
    app: {
      name: appName,
      version: appVersion,
      environment,
    },
  });

  return runtime;
}

export function enrichErrorEvent(faroAppId, environment, event) {
  if (!faro || environment === 'test') {
    return event;
  }

  const sessionId = faro.api.getSession().id;
  if (!event.hasOwnProperty('tags')) {
    // eslint-disable-next-line no-param-reassign
    event.tags = { session_id: null, session_url: null };
  }
  const now = new Date();
  // Set the 'from' time to 4 hours in the past, in case this is at the end of a session
  const fromDate = new Date(now);
  fromDate.setHours(now.getHours() - 4);

  // Set the 'to' time to 4 hours in the future, in case this is beginning of a session
  const toDate = new Date(now);
  toDate.setHours(now.getHours() + 4);
  // eslint-disable-next-line no-param-reassign
  event.tags.session_id = sessionId;
  // eslint-disable-next-line no-param-reassign
  event.tags.session_url = `https://clearbanc.grafana.net/a/grafana-kowalski-app/apps/${faroAppId}/sessions/${
    event.tags.session_id
  }?from=${fromDate.toISOString()}&to=${toDate.toISOString()}`;

  return event;
}

export function beforeBreadcrumbFilter(breadcrumb, _hint) {
  const ignoreUrls = ['faro-collector-prod-us-central-0.grafana.net'];

  const breadcrumbUrl = breadcrumb.data?.url;
  if (breadcrumbUrl && ignoreUrls.some((url) => breadcrumbUrl.includes(url))) {
    return null;
  }

  return breadcrumb;
}
